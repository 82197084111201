import saleAPI from "./saleAPI/product";
import tableAPI from "./tableAPI/table";
import itemAPI from "./itemAPI/item";
import menuAPI from "./menuAPI/menu";
import addOnsAPI from "./addOnsAPI/addOns";
import kitchenAPI from "./kitchenAPI/kitchen";
import discountAPI from "./discountAPI/discount";
import taxAPI from "./taxAPI/tax";
import chargesAPI from "./chargesAPI/charges";
import branchAPI from "./branchAPI/branch";
import varianceAPI from "./varianceAPI/variance";
import orderTypeAPI from "./orderTypeAPI/orderType";
import materialAPI from "./materialAPI/material";
import receipeAPI from './receipeAPI/receipe';
import kotRemarkAPI from './kotRemarkAPI/kotRemark';
import employeeAPI from './employeeAPI/employee';
import posAPI from './posAPI/pos';
import customerApi from './customerAPI/customer';
import accountApi from './accountAPI/account';
import userAPI from './userAPI/user';
import paymentModeAPI from './paymentModeAPI/paymentMode';
import generalSettingAPI from './generalSettingAPI/generalSetting';
import salesOrdersAPI from './salesOrdersAPI/salesOrders';
import printTemplateAPI from './printTemplateAPI/printTemplate';
import recieptAPI from './recieptAPI/reciept';
import vendorAPI from './vendorAPI/vendor';
import integrationAPI from "./integrationAPI/integration";


export default {
  saleAPI,
  tableAPI,
  itemAPI,
  menuAPI,
  addOnsAPI,
  kitchenAPI,
  discountAPI,
  taxAPI,
  chargesAPI,
  branchAPI,
  varianceAPI,
  orderTypeAPI,
  materialAPI,
  receipeAPI,
  kotRemarkAPI,
  employeeAPI,
  posAPI,
  customerApi,
  accountApi,
  userAPI,
  paymentModeAPI,
  generalSettingAPI,
  salesOrdersAPI,
  printTemplateAPI,
  recieptAPI,
  vendorAPI,
  integrationAPI
};

import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Card, Offcanvas, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getBranchList,
  getLoading,
  getmapUnmapBranchWithPhonenumberSuccessRes,
  getphoneNumberDetailsWithBranchMappedList,
  getverifyOTPRes,
} from "../../../application/selectors/indexSelector";
import OTPInput from "react-otp-input";
import {
  mapUnMapBranchWithPhoneNumber,
  mapUnMapBranchWithPhoneNumberSuccess,
  registerPhoneNumber,
  sendWPPhoneNumberVerificationOtp,
  verifyOTP,
  verifyOTPSuccess,
} from "../../../application/actions/integrationAction";
import LoadingSpinner from "../../_common/LoadingSpinner";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";
import Environment from "../../../infrastructure/core/Environment";
import PinInput from "react-pin-input";

function PhoneNumberDetailsManage(props) {
  const dispatch = useDispatch();
  const wpfbDetails = JSON.parse(localStorage.getItem("wpfbDetails"));
  const selectedBranch = JSON.parse(localStorage.getItem("branch"));
  const [branchMappedList, setBranchMappedList] = useState([]);
  const [otp, setOtp] = useState("");
  const loading = useSelector(getLoading).loading;
  const value = useSelector(getLoading).value;
  const { user } = useContext(AuthContext);
  const [pin, setPin] = useState("");
  const pinRef = useRef(null);
  const phoneNumberDetailsWithBranchMappedList = useSelector(
    getphoneNumberDetailsWithBranchMappedList
  );
  const mapUnmapBranchWithPhonenumberSuccessRes = useSelector(
    getmapUnmapBranchWithPhonenumberSuccessRes
  );
  const verifyOTPRes = useSelector(getverifyOTPRes);
  const [isLoading, setIsLoading] = useState({
    value: "",
    loader: false,
    branchId: Environment.defaultValue,
  });
  const branchList = useSelector(getBranchList);
  const BranchType = branchList?.filter(
    (branch) =>
      branch.wpPhoneNumberId === null ||
      branch.wpPhoneNumberId === Environment.defaultValue ||
      branch.wpPhoneNumberId ===
        phoneNumberDetailsWithBranchMappedList?.phoneNumberDetailResponse?.id
  );
  useEffect(() => {
    if (phoneNumberDetailsWithBranchMappedList?.branchMappedList) {
      setBranchMappedList(
        phoneNumberDetailsWithBranchMappedList?.branchMappedList
      );
    }
  }, [phoneNumberDetailsWithBranchMappedList]);
  const handleSendOtp = () => {
    const formObject = {
      CodeMethod: "SMS",
      Language: "en_US",
      phoneNumberId:
        phoneNumberDetailsWithBranchMappedList?.phoneNumberDetailResponse
          ?.phoneNumberId,
      AccessToken: wpfbDetails.accessToken,
      TenantId: user.tenanatId,
      WabaId: wpfbDetails.wabaId,
    };
    dispatch(sendWPPhoneNumberVerificationOtp(formObject));
  };
  const handleVerifyOTP = () => {
    setIsLoading({
      loader: true,
      value: "handleVerifyOTP",
      branchId: Environment.defaultValue,
    });
    const formObject = {
      Code: otp,
      Id: phoneNumberDetailsWithBranchMappedList?.phoneNumberDetailResponse?.id,
      phoneNumberId:
        phoneNumberDetailsWithBranchMappedList?.phoneNumberDetailResponse
          ?.phoneNumberId,
      AccessToken: wpfbDetails.accessToken,
      TenantId: user.tenanatId,
      wabaId: wpfbDetails.wabaId,
    };
    dispatch(verifyOTP(formObject));
  };
  useEffect(() => {
    if (
      verifyOTPRes &&
      isLoading.loader &&
      isLoading.value === "handleVerifyOTP"
    ) {
      setIsLoading({
        loader: false,
        value: "",
        branchId: Environment.defaultValue,
      });
      dispatch(verifyOTPSuccess(false));
    }
  }, [verifyOTPRes]);
  const isBranchMapped = (branchId) => {
    return branchMappedList.some(
      (mappedBranch) => mappedBranch.id === branchId
    );
  };
  const handleMappedLocation = (branch, isChecked, phoneNumberId) => {
    setIsLoading({
      loader: true,
      value: "handleMappedLocation",
      branchId: branch.id,
    });
    const formObject = {
      TenantId: user.tenanatId,
      BranchId: branch.id,
      IsChecked: isChecked,
      PhoneNumberId: phoneNumberId,
      wabaId: wpfbDetails.wabaId,
      accessToken: wpfbDetails.accessToken,
    };
    dispatch(mapUnMapBranchWithPhoneNumber(formObject));
  };
  useEffect(() => {
    if (
      mapUnmapBranchWithPhonenumberSuccessRes &&
      isLoading.loader &&
      isLoading.value === "handleMappedLocation"
    ) {
      setIsLoading({
        loader: false,
        value: "",
        branchId: Environment.defaultValue,
      });
      dispatch(mapUnMapBranchWithPhoneNumberSuccess(false));
    }
  }, [mapUnmapBranchWithPhonenumberSuccessRes]);

  const onChange = (value) => {
    setPin(value);
  };
  const handleRegisterPhoneNumber = () => {
    setIsLoading({
      loader: true,
      value: "handleRegisterPhoneNumber",
      branchId: Environment.defaultValue,
    });
    const formObject = {
      MessagingProduct: "whatsapp",
      Pin: pin,
      PhoneNumberId:
        phoneNumberDetailsWithBranchMappedList?.phoneNumberDetailResponse
          ?.phoneNumberId,
      Id: phoneNumberDetailsWithBranchMappedList?.phoneNumberDetailResponse?.id,
      AccessToken: wpfbDetails.accessToken,
      TenantId: user.tenanatId,
      BranchId: selectedBranch?.value,
    };
    dispatch(registerPhoneNumber(formObject));
  };
  useEffect(() => {
    if (
      mapUnmapBranchWithPhonenumberSuccessRes &&
      isLoading.loader &&
      isLoading.value === "handleRegisterPhoneNumber"
    ) {
      setIsLoading({
        loader: false,
        value: "",
        branchId: Environment.defaultValue,
      });
      dispatch(mapUnMapBranchWithPhoneNumberSuccess(false));
    }
  }, [mapUnmapBranchWithPhonenumberSuccessRes]);

  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-40"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">
            Phone Number Details
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row className="mx-2">
            <Table className="table table-borderless table-sm">
              <tbody>
                <tr>
                  <td>Verified Name: </td>
                  <td>
                    <span className="fw-bolder">
                      {
                        phoneNumberDetailsWithBranchMappedList
                          ?.phoneNumberDetailResponse?.verifiedName
                      }
                    </span>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Verification Status: </td>
                  <td>
                    <span
                      class={`badge bg-${
                        phoneNumberDetailsWithBranchMappedList
                          ?.phoneNumberDetailResponse
                          ?.codeVerificationStatus === "VERIFIED"
                          ? "success"
                          : "danger"
                      }`}
                    >
                      {phoneNumberDetailsWithBranchMappedList
                        ?.phoneNumberDetailResponse?.codeVerificationStatus ===
                      "VERIFIED"
                        ? "Verified"
                        : "Not Verified"}
                    </span>
                  </td>
                  <td className="otp-input-field">
                    {phoneNumberDetailsWithBranchMappedList
                      ?.phoneNumberDetailResponse?.codeVerificationStatus ===
                      "NOT_VERIFIED" &&
                    phoneNumberDetailsWithBranchMappedList
                      ?.phoneNumberDetailResponse?.isVerified === false &&
                    phoneNumberDetailsWithBranchMappedList
                      ?.phoneNumberDetailResponse?.isCodeSent === false ? (
                      <>
                        <Button
                          variant="primary"
                          className="fs-12 text-uppercase btn-sm"
                          onClick={() => handleSendOtp()}
                          disabled={
                            loading &&
                            value === "sendWPPhoneNumberVerificationOtp"
                          }
                        >
                          {loading &&
                          value === "sendWPPhoneNumberVerificationOtp" ? (
                            <LoadingSpinner
                              color="#ffffff"
                              size={30}
                              type={"TailSpin"}
                            />
                          ) : (
                            "Send Otp"
                          )}
                        </Button>
                      </>
                    ) : (
                      <>
                        {phoneNumberDetailsWithBranchMappedList
                          ?.phoneNumberDetailResponse
                          ?.codeVerificationStatus === "NOT_VERIFIED" &&
                          phoneNumberDetailsWithBranchMappedList
                            ?.phoneNumberDetailResponse?.isVerified === false &&
                          phoneNumberDetailsWithBranchMappedList
                            ?.phoneNumberDetailResponse?.isCodeSent ===
                            true && (
                            <div className="d-flex">
                              <div>
                                <OTPInput
                                  value={otp}
                                  onChange={setOtp}
                                  numInputs={6}
                                  renderSeparator={<span>-</span>}
                                  renderInput={(props) => <input {...props} />}
                                />
                              </div>
                              <div className="mx-2">
                                <Button
                                  variant="primary"
                                  className="btn-sm"
                                  disabled={
                                    (loading &&
                                      value === "verifyOTP" &&
                                      verifyOTPRes) ||
                                    (isLoading.loader &&
                                      isLoading.value === "handleVerifyOTP")
                                  }
                                  onClick={handleVerifyOTP}
                                >
                                  {(loading &&
                                    value === "verifyOTP" &&
                                    verifyOTPRes) ||
                                  (isLoading.loader &&
                                    isLoading.value === "handleVerifyOTP") ? (
                                    <LoadingSpinner
                                      color="#ffffff"
                                      size={15}
                                      type={"TailSpin"}
                                    />
                                  ) : (
                                    "Verify OTP"
                                  )}
                                </Button>
                              </div>
                            </div>
                          )}
                      </>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Registration Status: </td>
                  <td>
                    <span
                      class={`badge bg-${
                        phoneNumberDetailsWithBranchMappedList
                          ?.phoneNumberDetailResponse?.pin !== null &&
                        phoneNumberDetailsWithBranchMappedList
                          ?.phoneNumberDetailResponse?.isPinSet
                          ? "success"
                          : "danger"
                      }`}
                    >
                      {phoneNumberDetailsWithBranchMappedList
                        ?.phoneNumberDetailResponse?.pin !== null &&
                      phoneNumberDetailsWithBranchMappedList
                        ?.phoneNumberDetailResponse?.isPinSet
                        ? "Registered"
                        : "Not Registered"}
                    </span>
                  </td>
                  <td className="otp-input-field">
                    {(phoneNumberDetailsWithBranchMappedList
                      ?.phoneNumberDetailResponse?.pin === null ||
                      phoneNumberDetailsWithBranchMappedList
                        ?.phoneNumberDetailResponse?.pin === "") &&
                      !phoneNumberDetailsWithBranchMappedList
                        ?.phoneNumberDetailResponse?.isPinSet && (
                        <>
                          <div
                            class="d-flex"
                            tabindex="0"
                            {...(phoneNumberDetailsWithBranchMappedList
                              ?.phoneNumberDetailResponse
                              ?.codeVerificationStatus === "NOT_VERIFIED" &&
                              phoneNumberDetailsWithBranchMappedList
                                ?.phoneNumberDetailResponse?.isVerified ===
                                false && {
                                "data-toggle": "tooltip",
                                "data-placement": "right",
                                title: "Verify Your Phone Number",
                              })}
                          >
                            <div>
                              <PinInput
                                length={6}
                                focus
                                disabled={
                                  phoneNumberDetailsWithBranchMappedList
                                    ?.phoneNumberDetailResponse
                                    ?.codeVerificationStatus ===
                                    "NOT_VERIFIED" &&
                                  phoneNumberDetailsWithBranchMappedList
                                    ?.phoneNumberDetailResponse?.isVerified ===
                                    false
                                }
                                secret
                                ref={pinRef}
                                type="numeric"
                                onChange={onChange}
                              />
                            </div>
                            <div className="mx-2">
                              <Button
                                variant="primary"
                                className="btn-sm"
                                disabled={
                                  (loading &&
                                    value === "registerPhoneNumber" &&
                                    mapUnmapBranchWithPhonenumberSuccessRes &&
                                    phoneNumberDetailsWithBranchMappedList ===
                                      undefined) ||
                                  (isLoading.loader &&
                                    isLoading.value ===
                                      "handleRegisterPhoneNumber") ||
                                  (phoneNumberDetailsWithBranchMappedList
                                    ?.phoneNumberDetailResponse
                                    ?.codeVerificationStatus ===
                                    "NOT_VERIFIED" &&
                                    phoneNumberDetailsWithBranchMappedList
                                      ?.phoneNumberDetailResponse
                                      ?.isVerified === false)
                                }
                                onClick={handleRegisterPhoneNumber}
                              >
                                {(loading &&
                                  value === "registerPhoneNumber" &&
                                  mapUnmapBranchWithPhonenumberSuccessRes &&
                                  phoneNumberDetailsWithBranchMappedList ===
                                    undefined) ||
                                (isLoading.loader &&
                                  isLoading.value ===
                                    "handleRegisterPhoneNumber") ? (
                                  <LoadingSpinner
                                    color="#ffffff"
                                    size={15}
                                    type={"TailSpin"}
                                  />
                                ) : (
                                  "Register"
                                )}
                              </Button>
                            </div>
                          </div>
                        </>
                      )}
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>Certificate Verification: </td>
                  <td>
                    <span
                      class={`badge bg-${
                        phoneNumberDetailsWithBranchMappedList
                          ?.phoneNumberDetailResponse?.isCertVerified
                          ? "success"
                          : "danger"
                      }`}
                    >
                      {phoneNumberDetailsWithBranchMappedList
                        ?.phoneNumberDetailResponse?.isCertVerified
                        ? "Verified"
                        : "Not Verified"}
                    </span>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Phone Number: </td>
                  <td>
                    <span className="fw-bolder">
                      {
                        phoneNumberDetailsWithBranchMappedList
                          ?.phoneNumberDetailResponse?.displayPhoneNumber
                      }
                    </span>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </Table>
          </Row>
          <Card className="card-one">
            <Card.Header>
              <Card.Title as="h6">Map Branches</Card.Title>
            </Card.Header>
            <Card.Body className="p-3">
              <Row>
                {/* {(!phoneNumberDetailsWithBranchMappedList?.phoneNumberDetailResponse?.isVerified || !phoneNumberDetailsWithBranchMappedList?.phoneNumberDetailResponse?.isCertVerified)  && (
                    <div className={`login-facebook-bttn`}>
                      <h6 className="fs-18 fw-bolder text-danger">
                        Complete Verification Of The Phone Number Is Pending
                      </h6>
                    </div>
                  )} */}

                <Table>
                  <thead>
                    <tr>
                      <th>Location</th>
                    </tr>
                  </thead>
                  <tbody>
                    {BranchType?.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <label className="cursor-pointer">
                            {isLoading.loader &&
                            isLoading.value === "handleMappedLocation" &&
                            isLoading.branchId === item.id ? (
                              <LoadingSpinner
                                color="#005cc8"
                                size={15}
                                type={"TailSpin"}
                              />
                            ) : (
                              <input
                                type="checkbox"
                                className="wt-18 ht-18 align-middle"
                                onChange={() =>
                                  handleMappedLocation(
                                    item,
                                    !isBranchMapped(item.id),
                                    phoneNumberDetailsWithBranchMappedList
                                      ?.phoneNumberDetailResponse?.id
                                  )
                                }
                                checked={isBranchMapped(item.id)}
                              />
                            )}
                            <span className="ms-2 align-middle">
                              {item.branchName}
                            </span>
                          </label>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Row>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
        <div className="offcanvas-footer justify-content-start"></div>
      </Offcanvas>
    </React.Fragment>
  );
}

export default PhoneNumberDetailsManage;

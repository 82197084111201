//React packages
import React, { useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import { Form, Row, Col, Button, Card, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
//Images
import invoicecon from "../../../../assets/img/icons/customer/invoice.png";
import cardcon from "../../../../assets/img/icons/customer/credit-card.png";
import businessIcon from "../../../../assets/img/icons/customer/business.png";
import googleMapIcon from "../../../../assets/img/icons/customer/map.png";
//Address
import AddNewAddress from "./add-address";
import Changeaddress from "./change-address";
import AddNewCustomer from "../../../Customers/add-customer";
//Actions and selectors
import {
  getCreateOrderSuccess,
  getCustomerDetail,
  getCustomerList,
  getCustomerSearchedList,
  getLoading,
} from "../../../../application/selectors/indexSelector";
import {
  customerListByTenant,
  getSearchedListOfCustomer,
  updateCustomerDetailsInOrder,
} from "../../../../application/actions/customerAction";
import Environment from "../../../../infrastructure/core/Environment";
import BusinessReviewMd from "./business-review";
import AuthContext from "../../../../infrastructure/core/helpers/AuthContext";
import axios from "axios";
import ToastifyService from "../../../_common/ToastifyService";
import LoadingSpinner from "../../../_common/LoadingSpinner";

export default function MyCardCustomer(props) {
  const [isLoading, setIsLoading] = useState({ loader: false, value: "" });
  //accessing hook from react-redux package
  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);
  //fetching and storing customer list
  const customerListData = useSelector(getCustomerList);
  const customerDetails = useSelector(getCustomerDetail);
  const createOrderSuccess = useSelector(getCreateOrderSuccess);
  //making useState for customer data
  const [getCustomerDetails, setCustomerDetails] = useState(null);
  const [custDefaultAddress, setCustDefaultAddress] = useState(null);
  const [showAddNewAddree, setShowAddNewAddree] = useState(false);
  const [showAddNewCustomer, setShowAddNewCustomer] = useState(false);
  const [showChangeAddress, setShowChangeAddress] = useState(false);
  const [operationValue, setOperationValue] = useState("AddNewAddress");
  const [addressId, setAddressId] = useState(Environment.defaultValue);
  const loading = useSelector(getLoading).loading;
  const value = useSelector(getLoading).value;
  //manipulating the customer name and number list as needed in select package of react
  const customerContactList = customerListData?.map((option) => ({
    value: option.id,
    label: option.contactNumber,
  }));
  const wpfbDetails = JSON.parse(localStorage.getItem("wpfbDetails"));

  useEffect(() => {
    Prism.highlightAll();
    dispatch(customerListByTenant(user?.tenanatId));
  }, [user?.tenanatId]);
  useEffect(() => {
    if (customerDetails && value === "addCustomerAddress") {
      setCustomerDetails(customerDetails);
      const defaultAddress =
        customerDetails?.userAddresses &&
        customerDetails?.userAddresses?.length > 0
          ? customerDetails?.userAddresses?.find(
              (address) => address.isDefault && address.isDeleted === false
            )
          : null;
      setCustDefaultAddress(defaultAddress);
      props.onCustomerSelect(customerDetails?.id);
      props.onCustomerAddressSelect(defaultAddress?.id);
      setCustomer(customerDetails);
      setPhoneNumber(customerDetails?.contactNumber);
    } else if (createOrderSuccess) {
      if (createOrderSuccess.addressId && createOrderSuccess.customerId) {
        const correspondingCustomerNumber = customerContactList?.find(
          (customer) => customer.value === createOrderSuccess.customerId
        );
        const selectedCustomerData = customerListData?.find(
          (customerData) => customerData.id === createOrderSuccess.customerId
        );
        setCustomerDetails(selectedCustomerData);
        // fetch default address
        const defaultAddress =
          selectedCustomerData?.userAddresses &&
          selectedCustomerData?.userAddresses?.length > 0
            ? selectedCustomerData?.userAddresses?.find(
                (address) => address.id === createOrderSuccess.addressId
              )
            : null;
        setCustDefaultAddress(defaultAddress);
        setCustomer(selectedCustomerData);
        setPhoneNumber(correspondingCustomerNumber?.label);
        props.onCustomerSelect(selectedCustomerData?.id);
        props.onCustomerAddressSelect(defaultAddress?.id);
      }
    } else if (
      props?.customerFormData?.customerId !== Environment.defaultValue
    ) {
      const correspondingCustomerNumber = customerContactList?.find(
        (customer) => customer.value === props?.customerFormData?.customerId
      );
      const selectedCustomerData = customerListData?.find(
        (customerData) =>
          customerData.id === props?.customerFormData?.customerId
      );
      setCustomerDetails(selectedCustomerData);
      // fetch default address
      const defaultAddress =
        selectedCustomerData?.userAddresses &&
        selectedCustomerData?.userAddresses?.length > 0
          ? selectedCustomerData?.userAddresses?.find(
              (address) => address.isDefault && address.isDeleted === false
            )
          : null;
      setCustDefaultAddress(defaultAddress);
      props.onCustomerSelect(props?.customerFormData?.customerId);
      props.onCustomerAddressSelect(defaultAddress?.id);
      setCustomer(selectedCustomerData);
      setPhoneNumber(correspondingCustomerNumber?.label);
    }
  }, [customerDetails, createOrderSuccess]);

  //Onchange of number and name selection set the customer details and accordingly fetch default address
  const handleChange = (selected, type) => {
    if (selected) {
      if (type === "number") {
        setCustomer(selected);
        setPhoneNumber(selected.contactNumber);
      }
      const selectedCustomerData = customerListData.find(
        (customerData) => customerData.id === selected.id
      );
      setCustomerDetails(selectedCustomerData);
      const defaultAddress =
        selectedCustomerData?.userAddresses &&
        selectedCustomerData?.userAddresses?.length > 0
          ? selectedCustomerData?.userAddresses?.find(
              (address) => address.isDefault && address.isDeleted === false
            )
          : null;
      setCustDefaultAddress(defaultAddress);
      if (createOrderSuccess) {
        const formObject = {
          orderId: createOrderSuccess?.id,
          customerId: selectedCustomerData?.id,
          addressId: defaultAddress?.id,
        };
        const formObjectData = {
          formObject: formObject,
          orderTypeId: createOrderSuccess?.orderTypeId,
        };
        dispatch(updateCustomerDetailsInOrder(formObjectData));
      }
      props.onCustomerSelect(selectedCustomerData?.id);
      props.onCustomerAddressSelect(defaultAddress?.id);
    }
  };

  //open add and edit address modal
  const handleAddNewAddress = (state, addressId, data) => {
    setShowAddNewAddree(state);
    setAddressId(addressId);
    setOperationValue(data);
  };

  //open add customer modal
  const handleAddCustomer = (state) => {
    setShowAddNewCustomer(state);
  };

  //open change address modal
  const handleChangeAddress = (state) => {
    setShowChangeAddress(state);
  };

  const [showBusinessReview, setShowBusinessReview] = useState(false);
  const handleBusinessReview = (state) => {
    setShowBusinessReview(state);
  };

  const [phoneNumber, setPhoneNumber] = useState("");
  const [customer, setCustomer] = useState(null);
  const [customerList, setCustomerList] = useState(undefined);
  const [error, setError] = useState("");
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const customerSearchedList = useSelector(getCustomerSearchedList);

  const fetchCustomer = async (phone) => {
    setError("");
    dispatch(getSearchedListOfCustomer(phone));
  };
  useEffect(() => {
    if (customerSearchedList) {
      setCustomerList(customerSearchedList);
    }
  }, [customerSearchedList]);

  const handlePhoneNumberChange = (e) => {
    const { value } = e.target;
    setPhoneNumber(value);
    setMenuIsOpen(value.length >= 7 && customerList?.length !== 0); // Show options if input length is 7 or more
    setShowAddNewCustomer(false); // Reset add customer modal visibility on input change
    if (value.length === 10 && customerList?.length === 0) {
      setShowAddNewCustomer(true); // Open add customer modal if no results after 10 digits
      setCustomerList(undefined);
    } else if (value.length >= 7) {
      // Call API to fetch customer data
      fetchCustomer(value);
    } else {
      setCustomer(null); // Reset customer on input change
      setCustomerList(undefined);
    }
  };
  const handleSendInvoiceMessage = async () => {
    setIsLoading({ loader: true, value: "handleSendInvoiceMessage" });
    const response = await axios.post(
      `https://graph.facebook.com/v20.0/405843045937016/messages`,
      {
        messaging_product: "whatsapp",
        recipient_type: "individual",
        to: "917666275213",
        type: "template",
        template: {
          name: "order_invoice_message",
          language: {
            code: "en_US",
          },
          components: [
            {
              type: "header",
              parameters: [
                {
                  type: "document",
                  document: {
                    link: "https://fudeasy.oss-me-east-1.aliyuncs.com/WhatsappIntegrationTesting/FudEasy_Invoice.pdf",
                  },
                },
              ],
            },
          ],
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer EAAOTlgInZBTABOyjj2FtfdKM4FZBfxJWv5CV0ZBHth4tHxRyqbMU0QZAQwcQGXrP7DOBamqY3zfjix0Ii7zcpdzjJsCB9U8HKbBn4o0ZAuvJiZATHtB8ZBqdPhemiCFqcSKZAkz6CuIbAhQLjJ3wRNdnU9t8MCs5idhd7IHUnqteu9luGrVpZCZAuDy6XFkTNMM68B2pZBhiWbOeA9EBX2c7A4IozqoGNvVonsM`,
        },
      }
    );
    console.log("response", response);
    setIsLoading({ loader: false, value: "handleSendInvoiceMessage" });
    if (response?.status === 200) {
      ToastifyService.success("Message Sent Successfully");
    } else {
      ToastifyService.error("Something went wrong");
    }
    // return response;
  };
  const sendOrderPaymentLink = async () => {
    setIsLoading({ loader: true, value: "sendOrderPaymentLink" });
    const response = await axios.post(
      `https://graph.facebook.com/v20.0/405843045937016/messages`,
      {
        messaging_product: "whatsapp",
        recipient_type: "individual",
        to: "917666275213",
        type: "template",
        template: {
          name: "order_placed_payment_msg",
          language: {
            code: "en_US",
          },
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer EAAOTlgInZBTABOyjj2FtfdKM4FZBfxJWv5CV0ZBHth4tHxRyqbMU0QZAQwcQGXrP7DOBamqY3zfjix0Ii7zcpdzjJsCB9U8HKbBn4o0ZAuvJiZATHtB8ZBqdPhemiCFqcSKZAkz6CuIbAhQLjJ3wRNdnU9t8MCs5idhd7IHUnqteu9luGrVpZCZAuDy6XFkTNMM68B2pZBhiWbOeA9EBX2c7A4IozqoGNvVonsM`,
        },
      }
    );
    console.log("response", response);
    setIsLoading({ loader: false, value: "sendOrderPaymentLink" });
    if (response?.status === 200) {
      ToastifyService.success("Message Sent Successfully");
    } else {
      ToastifyService.error("Something went wrong");
    }
    // return response;
  };
  const sendRateExperienceMessage = async () => {
    setIsLoading({ loader: true, value: "sendRateExperienceMessage" });
    const response = await axios.post(
      `https://graph.facebook.com/v20.0/405843045937016/messages`,
      {
        messaging_product: "whatsapp",
        recipient_type: "individual",
        to: "917666275213",
        type: "template",
        template: {
          name: "rate_your_experience_message",
          language: {
            code: "en",
          },
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer EAAOTlgInZBTABOyjj2FtfdKM4FZBfxJWv5CV0ZBHth4tHxRyqbMU0QZAQwcQGXrP7DOBamqY3zfjix0Ii7zcpdzjJsCB9U8HKbBn4o0ZAuvJiZATHtB8ZBqdPhemiCFqcSKZAkz6CuIbAhQLjJ3wRNdnU9t8MCs5idhd7IHUnqteu9luGrVpZCZAuDy6XFkTNMM68B2pZBhiWbOeA9EBX2c7A4IozqoGNvVonsM`,
        },
      }
    );
    console.log("response", response);
    setIsLoading({ loader: false, value: "sendRateExperienceMessage" });
    if (response?.status === 200) {
      ToastifyService.success("Message Sent Successfully");
    } else {
      ToastifyService.error("Something went wrong");
    }
    // return response;
  };
  const sendMapLocation = async () => {
    setIsLoading({ loader: true, value: "sendMapLocation" });
    const response = await axios.post(
      `https://graph.facebook.com/v20.0/405843045937016/messages`,
      {
        messaging_product: "whatsapp",
        recipient_type: "individual",
        to: "917666275213",
        type: "template",
        template: {
          name: "sharing_location_message",
          language: {
            code: "en_US",
          },
          components: [
            {
              type: "header",
              parameters: [
                {
                  type: "location",
                  location: {
                    latitude: "19.0231984",
                    longitude: "73.022712",
                    name: "Arabian Dastar",
                    address:
                      "27 Plot, Shop No.17, 18, 19, Olive Estate, Sector, 104, Jagatguru Aadi Shankracharya Marg, Nerul, Navi Mumbai, Maharashtra 400706",
                  },
                },
              ],
            },
          ],
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer EAAOTlgInZBTABOyjj2FtfdKM4FZBfxJWv5CV0ZBHth4tHxRyqbMU0QZAQwcQGXrP7DOBamqY3zfjix0Ii7zcpdzjJsCB9U8HKbBn4o0ZAuvJiZATHtB8ZBqdPhemiCFqcSKZAkz6CuIbAhQLjJ3wRNdnU9t8MCs5idhd7IHUnqteu9luGrVpZCZAuDy6XFkTNMM68B2pZBhiWbOeA9EBX2c7A4IozqoGNvVonsM`,
        },
      }
    );
    console.log("response", response);
    setIsLoading({ loader: false, value: "sendMapLocation" });
    if (response?.status === 200) {
      ToastifyService.success("Message Sent Successfully");
    } else {
      ToastifyService.error("Something went wrong");
    }
    // return response;
  };
  // useEffect(() => {
  //   if (phoneNumber.length === 10) {
  //     setShowAddNewCustomer(true); // Open add customer modal if no results after 10 digits
  //   } else if (phoneNumber.length >= 6) {
  //     // Call API to fetch customer data
  //     fetchCustomer(phoneNumber);
  //   } else {
  //     setCustomer(null); // Reset customer on input change
  //   }
  // }, [phoneNumber]);

  const handleBlur = () => {
    setTimeout(() => {
      setMenuIsOpen(false);
    }, 200); // Add a delay to allow click event to trigger before hiding the menu
  };

  const handleFocus = () => {
    if (phoneNumber?.length >= 7) {
      setMenuIsOpen(true);
    }
  };

  return (
    <React.Fragment>
      <AddNewAddress
        show={showAddNewAddree}
        closeFunction={handleAddNewAddress}
        operationValue={operationValue}
        getCustomerDetails={getCustomerDetails}
        custDefaultAddress={custDefaultAddress}
        addressId={addressId}
      />
      <Changeaddress
        show={showChangeAddress}
        closeFunction={handleChangeAddress}
        getCustomerDetails={getCustomerDetails}
        onCustomerAddressSelect={(selectedAddressId) => {
          props.onCustomerAddressSelect(selectedAddressId);
          const defaultAddress =
            getCustomerDetails?.userAddresses &&
            getCustomerDetails?.userAddresses?.length > 0
              ? getCustomerDetails?.userAddresses?.find(
                  (address) => address?.id === selectedAddressId
                )
              : null;
          setCustDefaultAddress(defaultAddress);
        }}
      />
      {loading &&
        (value === "updateCustomerAddress" ||
          value === "updateCustomerDetailsInOrder" ||
          value === "addCustomerAddress") && (
          <div className="card-loader">
            <Spinner />
          </div>
        )}

      <AddNewCustomer
        show={showAddNewCustomer}
        closeFunction={handleAddCustomer}
      />
      <BusinessReviewMd
        show={showBusinessReview}
        closeFunction={handleBusinessReview}
        getCustomerDetails={getCustomerDetails}
      />

      <Row>
        <Col xl={12}>
          <div className="mb-2 custom-dropdown-wrapper position-relative">
            <Form.Label className="fs-14">Customer Number</Form.Label>
            <div className="custom-drop-down z-index-2">
              <span className="dropdown-icon">+91</span>
              <Form.Control
                type="text"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                placeholder="Enter phone number"
                onFocus={handleFocus}
                onBlur={handleBlur}
                maxLength={10}
              />
            </div>
            {menuIsOpen && (
              <ul className="options-container">
                {customerList?.map((customer) => (
                  <li
                    key={customer.id}
                    onClick={() => handleChange(customer, "number")}
                    className="option"
                  >
                    <span className="fs-14">{customer.contactNumber}</span>
                    <small className="fs-12">{customer.fullName}</small>
                  </li>
                ))}
                {customerList?.length === 0 && phoneNumber?.length > 7 && (
                  <li className="d-block" onClick={handleAddCustomer}>
                    <a className="d-flex align-items-center justify-content-center pt-2 pb-2 text-center fs-12">
                      <i className="ri-add-line fs-16 lh-1"></i>
                      <span className="d-sm-inline"> Add New Customer</span>
                    </a>
                  </li>
                )}
              </ul>
            )}
          </div>
        </Col>
        <>
          {loading && value === "getSearchedListOfCustomer" && (
            <div className="customer-list-spinner">
              <Spinner
                className="align-middle"
                animation="border"
                variant="primary"
              />
            </div>
          )}
          {error && <p>{error}</p>}
          {customer ? (
            <div>
              <p className="fs-14">
                Name: <b>{customer.fullName}</b>
              </p>
            </div>
          ) : (
            phoneNumber?.length === 10 && <></>
          )}
        </>
      </Row>

      {/* ADD NEW ADDRESS AND SHIPPING ADDRESS */}
      <Row>
        {getCustomerDetails && getCustomerDetails?.userAddresses && (
          <>
            {getCustomerDetails?.userAddresses.length === 0 && (
              <Col sm={12} className="mt-4">
                <Button
                  variant="primary"
                  className="d-flex align-items-center justify-content-center pt-2 pb-2 text-center w-100"
                  onClick={() =>
                    handleAddNewAddress(
                      true,
                      Environment.defaultValue,
                      "AddNewAddress"
                    )
                  }
                >
                  <i className="ri-add-line fs-16 lh-1"></i>
                  <span className="d-sm-inline">Add New Address</span>
                </Button>
              </Col>
            )}
            {getCustomerDetails?.userAddresses.length !== 0 &&
              custDefaultAddress !== null &&
              custDefaultAddress && (
                <Col sm={12} className="mt-4">
                  <div className="mb-1">
                    <h3 className="fs-14">Shipping Address</h3>
                  </div>
                  <Row className="customer-address">
                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <span className="default-address">Default</span>
                      <Button
                        variant="danger"
                        className="text-uppercase fs-12"
                        onClick={() => handleChangeAddress(true)}
                      >
                        Change
                      </Button>
                    </div>
                    {custDefaultAddress !== null && custDefaultAddress && (
                      <>
                        <React.Fragment key={custDefaultAddress?.id}>
                          <Col xl={10}>
                            <p className="mb-0 fs-14">
                              <i className="ri-map-pin-line"></i>{" "}
                              {custDefaultAddress?.buildingName},{" "}
                              {custDefaultAddress?.roomNo},{" "}
                              {custDefaultAddress?.landmark},{" "}
                              {custDefaultAddress?.landmark},{" "}
                              {custDefaultAddress?.location},{" "}
                              {custDefaultAddress?.city},{" "}
                              {custDefaultAddress?.state},{" "}
                              {custDefaultAddress?.country}
                              {" - "}
                              {custDefaultAddress?.zipCode}.
                            </p>
                          </Col>
                        </React.Fragment>
                      </>
                    )}
                  </Row>
                  <div className="mt-2">
                    <Button
                      variant="primary"
                      className="w-100"
                      onClick={() =>
                        handleAddNewAddress(
                          true,
                          custDefaultAddress.id,
                          "UpdateAddress"
                        )
                      }
                    >
                      Update
                    </Button>
                  </div>
                </Col>
              )}
          </>
        )}
      </Row>

      <hr />

      {/* QUICK SHARE OPTION */}
      <h3 className="fs-14 text-dark mb-2">Quick Share Option</h3>
      <div className="share-customer-status position-relative">
        <Card className="card-one p-3 d-flex flex-row mb-2">
          <div className="card-icon">
            <img
              src={invoicecon}
              alt="icon"
              className="w-100 h-100 object-fit-contain"
            />
          </div>
          <div className="ms-3 w-70">
            <h4 className="card-value text-dark fw-semibold fs-16 mb-1 text-uppercase">
              E Invoice
            </h4>
            <label className="card-title text-dark fs-14 text-dark mb-1 text-uppercase">
              Share the page link for review
            </label>
          </div>
          <div className="payment-itegration-bttn">
            <Button
              variant="primary"
              className="fs-12 text-uppercase"
              onClick={handleSendInvoiceMessage}
              disabled={
                isLoading.loader &&
                isLoading.value === "handleSendInvoiceMessage"
              }
            >
              {isLoading.loader &&
              isLoading.value === "handleSendInvoiceMessage" ? (
                <LoadingSpinner color="#ffffff" size={30} type={"TailSpin"} />
              ) : (
                "Send"
              )}
            </Button>
          </div>
        </Card>

        <Card className="card-one p-3 d-flex flex-row mb-2">
          <div className="card-icon">
            <img
              src={cardcon}
              alt="icon"
              className="w-100 h-100 object-fit-contain"
            />
          </div>
          <div className="ms-3 w-70">
            <h4 className="card-value text-dark fw-semibold fs-16 mb-1 text-uppercase">
              Share Payment Link
            </h4>
            <label className="card-title text-dark fs-14 text-dark mb-1 text-uppercase">
              Share the payment link with customer.
            </label>
          </div>
          <div className="payment-itegration-bttn">
            <Button
              variant="primary"
              className="fs-12 text-uppercase"
              onClick={sendOrderPaymentLink}
              disabled={
                isLoading.loader && isLoading.value === "sendOrderPaymentLink"
              }
            >
              {isLoading.loader &&
              isLoading.value === "sendOrderPaymentLink" ? (
                <LoadingSpinner color="#ffffff" size={30} type={"TailSpin"} />
              ) : (
                "Send"
              )}
            </Button>
          </div>
        </Card>

        <Card className="card-one p-3 d-flex flex-row mb-2">
          <div className="card-icon">
            <img
              src={businessIcon}
              alt="icon"
              className="w-100 h-100 object-fit-contain"
            />
          </div>
          <div className="ms-3 w-70">
            <h4 className="card-value text-dark fw-semibold fs-16 mb-1 text-uppercase">
              Ask for Business Review
            </h4>
            <label className="card-title text-dark fs-14 text-dark mb-1 text-uppercase">
              Share the page link for review.
            </label>
          </div>
          <div className="payment-itegration-bttn">
            <Button
              variant="primary"
              className="fs-12 text-uppercase"
              // onClick={() => handleBusinessReview(true)}
              onClick={sendRateExperienceMessage}
              disabled={
                isLoading.loader &&
                isLoading.value === "sendRateExperienceMessage"
              }
            >
              {isLoading.loader &&
              isLoading.value === "sendRateExperienceMessage" ? (
                <LoadingSpinner color="#ffffff" size={30} type={"TailSpin"} />
              ) : (
                "Send"
              )}
            </Button>
          </div>
        </Card>

        <Card className="card-one p-3 d-flex flex-row mb-2">
          <div className="card-icon">
            <img
              src={googleMapIcon}
              alt="icon"
              className="w-100 h-100 object-fit-contain"
            />
          </div>
          <div className="ms-3 w-70">
            <h4 className="card-value text-dark fw-semibold fs-16 mb-1 text-uppercase">
              Share Map Location
            </h4>
            <label className="card-title text-dark fs-14 text-dark mb-1 text-uppercase">
              Share the map location for direction.
            </label>
          </div>
          <div className="payment-itegration-bttn">
            <Button
              variant="primary"
              className="fs-12 text-uppercase"
              onClick={sendMapLocation}
              disabled={
                isLoading.loader && isLoading.value === "sendMapLocation"
              }
            >
              {isLoading.loader && isLoading.value === "sendMapLocation" ? (
                <LoadingSpinner color="#ffffff" size={30} type={"TailSpin"} />
              ) : (
                "Send"
              )}
            </Button>
          </div>
        </Card>
        {wpfbDetails &&
          (wpfbDetails.appId === null ||
            wpfbDetails.accessToken === null ||
            wpfbDetails.wabaId === null ||
            wpfbDetails.phoneNumberId === null ||
            wpfbDetails.codeVerificationStatus === null ||
            wpfbDetails.codeVerificationStatus === "NOT_VERIFIED" ||
            wpfbDetails.certificate === null ||
            wpfbDetails.isCertVerified === null ||
            wpfbDetails.isCertVerified === false ||
            wpfbDetails.isPinSet === null ||
            wpfbDetails.isPinSet === false) && (
            <div className="login-facebook-bttn">
              <h5>
                Connect Whatsapp From Our Integration Module Under System
                Setting{" "}
              </h5>
            </div>
          )}
      </div>
    </React.Fragment>
  );
}

import React, { useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import { Button, Card } from "react-bootstrap";
import "./integration.scss";
import pinelabsIcon from "../../../assets/img/integration/pinelabs.png";
import tallyPrimeIcon from "../../../assets/img/integration/tally-prime.png";
import paytmIcon from "../../../assets/img/integration/paytm.png";
import whatsappIcon from "../../../assets/img/integration/whatsapp.png";
import FacebookLogin from "../../Sales/FaceBookLogin/FacebookLogin";
import WhatsappManage from "./WhatsappManage";
import { useDispatch, useSelector } from "react-redux";
import {
  getLoading,
  getwabaDetails,
  getwabaPhoneNumberList,
} from "../../../application/selectors/indexSelector";
import LoadingSpinner from "./../../_common/LoadingSpinner";
import {
  wABABusinessDetails,
  wABAPhoneNumberListBranchMap,
} from "../../../application/actions/integrationAction";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";

export default function Integration() {
  const dispatch = useDispatch();
  const wabaDetails = useSelector(getwabaDetails);
  const wabaPhoneNumberList = useSelector(getwabaPhoneNumberList);
  const { user } = useContext(AuthContext);
  const wpfbDetails = JSON.parse(localStorage.getItem("wpfbDetails"));
  const [isWPDetailsModalOpen, setIsWPDetailsModalOpen] = useState(false);
  const loading = useSelector(getLoading).loading;
  const value = useSelector(getLoading).value;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    Prism.highlightAll();
  }, []);

  const handleWABADetails = () => {
    setIsLoading(true);
    const formObject = {
      wabaId: wpfbDetails?.wabaId,
      accessToken: wpfbDetails?.accessToken,
    };
    dispatch(wABABusinessDetails(formObject));
    dispatch(
      wABAPhoneNumberListBranchMap({
        ...formObject,
        tenantId: user?.tenanatId,
      })
    );
  };
  useEffect(() => {
    if (wabaDetails && wabaPhoneNumberList && isLoading) {
      setIsWPDetailsModalOpen(true);
      setIsLoading(false);
    }
  }, [wabaPhoneNumberList, wabaDetails]);

  return (
    <React.Fragment>
      <div className="top-header d-md-flex align-items-center justify-content-between mb-4 position-static">
        <div>
          <h4 className="fs-16 main-title mb-1">Integrations</h4>
        </div>
        <div className="d-flex gap-2 mt-3 mt-md-0">
          <Button variant="primary" className="d-flex align-items-center gap-1">
            <i className="ri-add-line fs-18 lh-1"></i>
            <span className="d-sm-inline">Add Integrations</span>
          </Button>
        </div>
      </div>

      <div>
        <Card className="card-one p-3 d-flex flex-row mb-2">
          <div className="card-icon">
            <img
              src={whatsappIcon}
              alt="icon"
              className="w-100 h-100 object-fit-contain"
            />
          </div>
          <div className="ms-3">
            <h4
              className="card-value text-dark fw-semibold fs-16 mb-1
              text-uppercase"
            >
              Whatsapp
            </h4>
            <label className="card-title text-dark fs-14 text-dark mb-1 text-uppercase">
              INSTANT MESSAGING AND VOICE-OVER-IP SERVICE
            </label>
            <p className="fs-xs text-dark mb-0 lh-4">
              Send text, voice messages and video messages, make voice and video
              calls, and share images, documents, user locations, and other
              content.
            </p>
          </div>
          <div className="payment-itegration-bttn">
          <FacebookLogin />
            {wpfbDetails &&
            (wpfbDetails.appId === null ||
              wpfbDetails.accessToken === null ||
              wpfbDetails.wabaId === null) ? (
              <FacebookLogin />
            ) : (
              <Button
                variant="primary"
                className="fs-12 text-uppercase"
                onClick={() => handleWABADetails()}
                disabled={
                  (loading &&
                    (value === "wABAPhoneNumberListBranchMap" ||
                      value === "exchangeTokenForAccessToken") &&
                    (wabaDetails === undefined ||
                      wabaPhoneNumberList === undefined)) ||
                  isLoading
                }
              >
                {(loading &&
                  (value === "wABAPhoneNumberListBranchMap" ||
                    value === "exchangeTokenForAccessToken") &&
                  (wabaDetails === undefined ||
                    wabaPhoneNumberList === undefined)) ||
                isLoading ? (
                  <LoadingSpinner color="#ffffff" size={20} type={"TailSpin"} />
                ) : (
                  "View"
                )}
              </Button>
            )}
          </div>
        </Card>

        <Card className="card-one p-3 d-flex flex-row mb-2">
          <div className="card-icon">
            <img
              src={pinelabsIcon}
              alt="icon"
              className="w-100 h-100 object-fit-contain"
            />
          </div>
          <div className="ms-3">
            <h4 className="card-value text-dark fw-semibold fs-16 mb-1 text-uppercase">
              Pinelabs
            </h4>
            <label className="card-title text-dark fs-14 text-dark mb-1 text-uppercase">
              DIGITAL-PAYMENT
            </label>
            <p className="fs-xs text-dark mb-0 lh-4">
              Securely collect payments via Cards, UPI, Wallets etc. at your
              storefront with Pine Labs EDCs.
            </p>
          </div>
          <div className="payment-itegration-bttn">
            <Button variant="primary" className="fs-12 text-uppercase">
              Sign up
            </Button>
          </div>
        </Card>

        <Card className="card-one p-3 d-flex flex-row mb-2">
          <div className="card-icon">
            <img
              src={tallyPrimeIcon}
              alt="icon"
              className="w-100 h-100 object-fit-contain"
            />
          </div>
          <div className="ms-3">
            <h4 className="card-value text-dark fw-semibold fs-16 mb-1 text-uppercase">
              Tally
            </h4>
            <label className="card-title text-dark fs-14 text-dark mb-1 text-uppercase">
              ACCOUNTING
            </label>
            <p className="fs-xs text-dark mb-0 lh-4">
              Seamlessly integrate Urbanpiper Prime with Tally Prime as your
              accounting system.
            </p>
          </div>
          <div className="payment-itegration-bttn">
            <Button variant="primary" className="fs-12 text-uppercase">
              Sign up
            </Button>
          </div>
        </Card>

        <Card className="card-one p-3 d-flex flex-row mb-2">
          <div className="card-icon">
            <img
              src={paytmIcon}
              alt="icon"
              className="w-100 h-100 object-fit-contain"
            />
          </div>
          <div className="ms-3">
            <h4 className="card-value text-dark fw-semibold fs-16 mb-1 text-uppercase">
              Paytm
            </h4>
            <label className="card-title text-dark fs-14 text-dark mb-1 text-uppercase">
              DIGITAL-PAYMENT
            </label>
            <p className="fs-xs text-dark mb-0 lh-4">
              Securely collect payments via Cards, UPI, Wallets etc. at your
              storefront with Paytm EDCs.
            </p>
          </div>
          <div className="payment-itegration-bttn">
            <Button variant="primary" className="fs-12 text-uppercase">
              View Details
            </Button>
          </div>
        </Card>
      </div>

      <WhatsappManage
        show={isWPDetailsModalOpen}
        closeFunction={() => setIsWPDetailsModalOpen(false)}
      />
    </React.Fragment>
  );
}

import { Navigate } from "react-router-dom";
import {
  ADD_DISCOUNT,
  DELETE_DISCOUNT,
  DISCOUNT_LIST,
  GET_DISCOUNT_BY_ID,
  GetDiscountByIdSuccess,
  GetDiscountSuccess,
  MAP_DISCOUNT_ORDERTYPE,
  MAP_DISCOUNT_WITH_BRANCH,
  UNMAP_DISCOUNT_ORDERTYPE,
  UNMAP_DISCOUNT_WITH_BRANCH,
} from "../actions/discountAction";
import * as Loading from "../actions/loaderAction";
import ToastifyService from "../../View/_common/ToastifyService";

const GetDiscountsList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === DISCOUNT_LIST) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "dicountList" }));
        const DiscountData = await api.discountAPI.GetDiscountsList();
        dispatch(GetDiscountSuccess(DiscountData));
        dispatch(Loading.setLoading({ loading: false, value: "dicountList" }));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const AddDiscount =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === ADD_DISCOUNT) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const DiscountData = await api.discountAPI.AddDiscount(
          action.payload.data
        );
        dispatch(GetDiscountByIdSuccess(DiscountData.result));
        if (action.payload.data.id) {
          ToastifyService.success(`Discount Updated Successfully!`);
        } else {
          ToastifyService.success(`New Discount Added Successfully!`);
        }
        dispatch(Loading.loadDiscount);
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const DeleteDiscount =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === DELETE_DISCOUNT) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const DiscountList = await api.discountAPI.DeleteDiscount(
          action.payload
        );
        dispatch(GetDiscountSuccess(DiscountList));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        ToastifyService.success("Discount Deleted Successfully! ");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const GetDiscountById =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_DISCOUNT_BY_ID) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const DiscountData = await api.discountAPI.GetDiscountById(
          action.payload.data
        );
        dispatch(GetDiscountByIdSuccess(DiscountData.result));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const MapDiscountOrdertype =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === MAP_DISCOUNT_ORDERTYPE) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const discountData = await api.discountAPI.MapDiscountOrdertype(
          action.payload
        );
        dispatch(GetDiscountByIdSuccess(discountData.result));

        dispatch(Loading.loadDiscount);
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        console.log(error);
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const UnMapDiscountOrdertype =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === UNMAP_DISCOUNT_ORDERTYPE) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const discountData = await api.discountAPI.UnMapDiscountOrdertype(
          action.payload
        );
        dispatch(GetDiscountByIdSuccess(discountData.result));
        dispatch(Loading.loadDiscount);
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        console.log(error);
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const MapDiscountWithBranch =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === MAP_DISCOUNT_WITH_BRANCH) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const DiscountData = await api.discountAPI.MapDiscountWithBranch(
          action.payload
        );
        dispatch(GetDiscountByIdSuccess(DiscountData.result));
        ToastifyService.success(`Discount Mapped with Braches Successfully!`);

        dispatch(Loading.loadDiscount);
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        console.log(error);
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const UnMapDiscountWithBranch =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === UNMAP_DISCOUNT_WITH_BRANCH) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const DiscountData = await api.discountAPI.UnMapDiscountWithBranch(
          action.payload
        );
        dispatch(GetDiscountByIdSuccess(DiscountData.result));
        dispatch(Loading.loadDiscount);
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        console.log(error);
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
export default [
  GetDiscountsList,
  AddDiscount,
  DeleteDiscount,
  MapDiscountWithBranch,
  UnMapDiscountWithBranch,
  GetDiscountById,
  MapDiscountOrdertype,
  UnMapDiscountOrdertype,
];

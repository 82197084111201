import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Row, Col, Form, Button, Card, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getLoading } from "../../../application/selectors/indexSelector";
import ReactDatePicker from "react-datepicker";
import MarkEmpList from "../../../Json/Attendance/mark-attendance.json";
import Select from 'react-select';
import TableShimmerLoader from "../../../layouts/ShimmerTable";

export default function MarkAttendace() {
    useEffect(() => {
        Prism.highlightAll();
    }, []);

    const [startDate, setStartDate] = useState(new Date());

    const [selectedOption, setSelectedOption] = useState(null);

    const options = [
        {
            value: "#0DB15F",
            label: "Present"
        },
        {
            value: "#00A8F0",
            label: "Half Day"
        },
        {
            value: "#F3F3F3",
            label: "Not Marked"
        },
        {
            value: "#F8B900",
            label: "Paid Leave"
        },
        {
            value: "#525252",
            label: "Work Off"
        }
    ];
    const handleChange = (selected) => {
        setSelectedOption(selected);
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: selectedOption ? selectedOption.value : null,
        }),
        singleValue: (provided) => ({
            ...provided

        }),
    };

    const { loading, value } = useSelector(getLoading);


    const [searchTerm, setSearchTerm] = useState('');
    const filteredEmployees = MarkEmpList?.filter((item) => Object.values(item).some((value) => value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())));

    return (
        <React.Fragment>
            <div className="mb-4">
                <Row className="gap-2">
                    <Col xl={8}>
                        <Row className="g-3">
                            <Col xl={4} md={4} sm={5}>
                                <Form.Select aria-label="Default select example" className="fs-13" name="branches">
                                    <option value="HID04">Location: Chembur - Chedda Nagar</option>
                                    <option value="HID036">Location: Nerul - Sector 27</option>
                                    <option value="HID037">Location: Mumbra - Opening Soon</option>
                                </Form.Select>
                            </Col>
                            <Col xl={4} md={4} sm={5}>
                                <ReactDatePicker dateFormat="MMMM dd, yyyy" selected={startDate} onChange={(date) => setStartDate(date)} className="form-control" />
                            </Col>
                            <Col xl={2} md={2} sm={2}>
                                <Button variant="dark" className="fs-12"><i className="ri-filter-line"></i> Filter</Button>
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </div>

            <Card className="card-one">
                <Card.Body>
                    <div className="d-md-flex align-items-center justify-content-between mb-3">
                        <div>
                            <h4 className="main-title fs-14 mb-0">Mark Employee Attendance</h4>
                        </div>
                        <div className="custom-dropdown-wrapper">
                            <div className="custom-drop-down z-index-2 wt-250">
                                <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                                <Form.Control type="text" placeholder="Search..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                            </div>
                        </div>
                    </div>

                    {filteredEmployees && filteredEmployees !== undefined && (
                        <Table className="mb-0" responsive>
                            <thead>
                                <tr>
                                    <th>Employee Name</th>
                                    <th>Todays Status</th>
                                    <th>Remark</th>
                                </tr>
                            </thead>

                            <tbody>
                                {filteredEmployees?.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.name}</td>
                                        <td className="wt-400">
                                            <Select
                                                value={selectedOption}
                                                onChange={handleChange}
                                                options={options}
                                                styles={customStyles}
                                                placeholder="Select a color"
                                            />
                                        </td>
                                        <td>
                                            <Form.Control type="text" placeholder="Enter Remark" name="remark" />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                    )}
                    {!loading && filteredEmployees === undefined && (
                        <TableShimmerLoader colNames={filteredEmployees?.map((column) => column.name)} colsCount={1} />
                    )}
                    {loading && value === "MarkEmpList" && filteredEmployees === undefined && (
                            <TableShimmerLoader colNames={filteredEmployees?.map((column) => column.name)} colsCount={1} />
                        )}
                </Card.Body>
            </Card>
        </React.Fragment>
    )
}
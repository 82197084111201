import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { useSelector } from "react-redux";
import { Row, Col, Form, Button, Card, Table } from "react-bootstrap";
import { getLoading } from "../../../application/selectors/indexSelector";
import monthsLists from "../../../Json/Attendance/months-report.json";
import TableShimmerLoader from "../../../layouts/ShimmerTable";

export default function MonthlyReport() {
    useEffect(() => {
        Prism.highlightAll();
    }, []);

    const { loading, value } = useSelector(getLoading);

    const [searchTerm, setSearchTerm] = useState('');
    const filteredEmployees = monthsLists?.filter((item) => Object.values(item).some((value) => value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())));

    return (
        <React.Fragment>
            <div className="mb-4">
                <Row className="g-3 align-items-center">
                    <Col xl={4} md={3} sm={12} className="mb-2">
                        <Button variant="success" className="fs-14" type="button">
                            <i className="ri-file-excel-2-line align-middle fs-18 me-2"></i> <span className="align-middle">Download Report</span>
                        </Button>
                    </Col>
                    <Col xl={8} md={9} sm={12}>
                        <Row className="justify-content-end g-2">
                            <Col xl={4} md={5} sm={5}>
                                <Form.Select aria-label="Default select example" className="fs-13" name="branches">
                                    <option value="HID04">Location: Chembur - Chedda Nagar</option>
                                    <option value="HID036">Location: Nerul - Sector 27</option>
                                    <option value="HID037">Location: Mumbra - Opening Soon</option>
                                </Form.Select>
                            </Col>
                            <Col xl={4} md={5} sm={5}>
                                <Form.Select aria-label="Default select example" className="fs-13" name="chooseMonth">
                                    <option>Choose Month</option>
                                    <option value="11/1/2023 12:00:00 AM">November - 2023</option>
                                    <option value="10/1/2023 12:00:00 AM">October - 2023</option>
                                </Form.Select>
                            </Col>
                            <Col xl={4} md={2} sm={2}>
                                <Button variant="dark" className="fs-12 w-100"><i className="ri-filter-line"></i> Filter</Button>
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </div>

            <Card className="card-one">
                <Card.Body>
                    <div className="d-md-flex align-items-center justify-content-between mb-3">
                        <div>
                            <h4 className="main-title fs-14 mb-0">Monthly Report</h4>
                        </div>
                        <div className="custom-dropdown-wrapper">
                            <div className="custom-drop-down z-index-2 wt-250">
                                <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                                <Form.Control type="text" placeholder="Search..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                            </div>
                        </div>
                    </div>

                    {filteredEmployees && filteredEmployees !== undefined && (
                        <Table className="mb-0" responsive>
                            <thead>
                                <tr>
                                    <th>Employee Name</th>
                                    <th>Present</th>
                                    <th>Paid Leave</th>
                                    <th>Half Day</th>
                                    <th>Absent</th>
                                    <th>Total Paid Days</th>
                                    <th>Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                {filteredEmployees?.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.name}</td>
                                        <td>{item.present}</td>
                                        <td><b>{item.paidLeave}</b></td>
                                        <td><b>{item.halfDay}</b></td>
                                        <td className={`${item.absent > 0 ? "text-danger" : ""}`}><b>{item.absent}</b></td>
                                        <td>{item.totalPaidDays}</td>
                                        <td className="w-0">
                                            <Button variant="primary" className="btn-icon"><i className="ri-pencil-line"></i></Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                    {!loading && filteredEmployees === undefined && (
                        <TableShimmerLoader colNames={filteredEmployees?.map((column) => column.name)} colsCount={1} />
                    )}
                    {loading && value === "monthsLists" && filteredEmployees === undefined && (
                            <TableShimmerLoader colNames={filteredEmployees?.map((column) => column.name)} colsCount={1} />
                        )}
                </Card.Body>
            </Card>
        </React.Fragment>
    )
}
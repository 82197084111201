import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import notification from "../data/Notification";
import AuthContext from "../infrastructure/core/helpers/AuthContext";
import { useDispatch, useSelector } from "react-redux";
import { getBranchList, getLoading } from "../application/selectors/indexSelector";
import Select, { components } from "react-select";
import { resetOrderTypeList } from "../application/actions/orderTypeAction";
import { loadBranch } from "../application/actions/loaderAction";
import { GetPOSMenuDataByBranchId, getWPFBDetails } from "../application/actions/posAction";
import LoadingSpinner from "../View/_common/LoadingSpinner";
import { Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import PrintConfiguration from "../View/_common/printer-configuration";
//created a loader for displaying it besides select dropdown untill the branchList is loaded
const CustomDropdownIndicator = ({ loading, value, ...props }) => (
  <components.DropdownIndicator {...props}>
    {loading ? (
      <LoadingSpinner color="#dc3545" size={20} type={"TailSpin"} />
    ) : null}
  </components.DropdownIndicator>
);
export default function Header({ passDataToPointOfSale }) {
  const { logout, setBranch, setSelectedBranchData } =
    useContext(AuthContext);
  const CustomToggle = useMemo(
    () =>
      React.forwardRef(({ children, onClick }, ref) => (
        <Link
          to=""
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}
          className="dropdown-link"
        >
          {children}
        </Link>
      )),
    []
  );

  const toggleSidebar = (e) => {
    e.preventDefault();
    let isOffset = document.body.classList.contains("sidebar-offset");
    if (isOffset) {
      document.body.classList.toggle("sidebar-show");
    } else {
      if (window.matchMedia("(max-width: 991px)").matches) {
        document.body.classList.toggle("sidebar-show");
      } else {
        document.body.classList.toggle("sidebar-hide");
      }
    }
  };

  function NotificationList() {
    const notiList = notification.map((item, key) => {
      return (
        <li className="list-group-item" key={key}>
          <div
            className={item.status === "online" ? "avatar online" : "avatar"}
          >
            {item.avatar}
          </div>
          <div className="list-group-body">
            <p>{item.text}</p>
            <span>{item.date}</span>
          </div>
        </li>
      );
    });

    return <ul className="list-group">{notiList}</ul>;
  }

  const skinMode = (e) => {
    e.preventDefault();
    e.target.classList.add("active");

    let node = e.target.parentNode.firstChild;
    while (node) {
      if (node !== e.target && node.nodeType === Node.ELEMENT_NODE)
        node.classList.remove("active");
      node = node.nextElementSibling || node.nextSibling;
    }

    let skin = e.target.textContent.toLowerCase();
    let HTMLTag = document.querySelector("html");

    if (skin === "dark") {
      HTMLTag.setAttribute("data-skin", skin);
      localStorage.setItem("skin-mode", skin);

      // onSkin(skin);
    } else {
      HTMLTag.removeAttribute("data-skin");
      localStorage.removeItem("skin-mode");

      //onSkin("");
    }
  };


  const dispatch = useDispatch();
  //getting loading and value of which api data is loading
  const loading = useSelector(getLoading).loading;
  const value = useSelector(getLoading).value;
  const { user } = useContext(AuthContext);
  const branchList = useSelector(getBranchList);
  const [selectedOptions, setSelectedOptions] = useState(
    JSON.parse(localStorage.getItem("branch"))
  );
  //setting loader on onclick of submit button for adding loader conditions
  const [buttonLoader, setButtonLoader] = useState(false);
  //fetching data from localstorage of menu for adding loader conditions
  const menuData = {
    menuList: localStorage.getItem("MenuList"),
    subMenuList: localStorage.getItem("SubMenuList"),
    catalogueList: localStorage.getItem("Catalogues"),
  };
  const BranchType = branchList?.map((option) => ({
    value: option.id,
    label: option.branchName,
  }));
  useEffect(() => {
    if (user?.tenanatId && branchList === undefined) {
      dispatch(loadBranch(user?.tenanatId));
    }
    if (selectedOptions) {
      passDataToPointOfSale(selectedOptions);
    }
  }, [user?.tenanatId]);
  useEffect(() => {
    if (branchList !== undefined) {
      setBranch(selectedOptions);
      setSelectedBranchData(
        JSON.stringify(branchList.find((branch) => branch?.id === selectedOptions?.value))
      );
      const formObject={
        tenantId:user?.tenanatId,
        branchId:selectedOptions?.value
      }
      dispatch(getWPFBDetails(formObject))
    }
  }, [branchList]);
  const handleSelectBranch = (selectedOption) => {
    dispatch(resetOrderTypeList);
    passDataToPointOfSale(selectedOption);
    const formObject={
      tenantId:user?.tenanatId,
      branchId:selectedOption?.value
    }
    dispatch(getWPFBDetails(formObject))
    setSelectedOptions(selectedOption?.value);
    localStorage.setItem("branch", JSON.stringify(selectedOption));
    localStorage.setItem(
      "selectedBranchData",
      JSON.stringify(branchList.find((branch) => branch?.id === selectedOption?.value))
    );
    setBranch(selectedOption);
    setSelectedBranchData(
      JSON.stringify(branchList.find((branch) => branch?.id === selectedOption?.value))
    );
    dispatch(GetPOSMenuDataByBranchId(selectedOption?.value));
  };

  const location = useLocation();
  const currentUrl = location.pathname;

  const [showPrintConfiguration, setShowPrintConfiguration] = useState(false);
  const handlePrintConfiguration = (state) => {
    setShowPrintConfiguration(state);
  };

  return (
    <React.Fragment>
      <PrintConfiguration show={showPrintConfiguration} closeFunction={handlePrintConfiguration} />
      <div className="header-main px-3 px-lg-4">
        <Link onClick={toggleSidebar} className="menu-link me-3 me-lg-4">
          <i className="ri-menu-2-fill"></i>
        </Link>

        {/* SEARCH BRANCH INPUT */}
        <div className="me-auto d-flex">
          {(currentUrl === "/item-catalogue") |
            (currentUrl === "/businessCatalog/menu") |
            (currentUrl === "/business-catalog/addons") |
            (currentUrl === "/business-catalog/charges") |
            (currentUrl === "/business-catalog/discount") |
            (currentUrl === "/taxGroup/manage") |
            (currentUrl === "/coupon-management") |
            (currentUrl === "/customer-loyalty") |
            (currentUrl === "/system-settings") ? (
            <></>
          ) : (
            <>
              <div className="form-search  p-0">
                <Select
                  name="branchId"
                  placeholder="Choose Branch"
                  options={BranchType}
                  onChange={handleSelectBranch}
                  value={BranchType?.find(
                    (option) => option.value === selectedOptions.value
                  )}
                  className="w-100 p-0"
                  id="choose_branch"
                  components={{
                    DropdownIndicator: (props) => (
                      <CustomDropdownIndicator
                        {...props}
                        loading={
                          (loading && value === "branchList") ||
                          branchList === undefined
                        }
                        value={value}
                      />
                    ),
                  }}
                />
              </div>
              {/* <Button
                variant="danger"
                className="ms-4"
                size="sm"
                onClick={() => {
                  setButtonLoader(true);
                  dispatch(GetPOSMenuDataByBranchId(selectedOptions?.value));
                }}
                disabled={
                  ((loading && value === "posMenuData") ||
                    (menuData.menuList === null &&
                      menuData.subMenuList === null &&
                      menuData.catalogueList === null)) &&
                  buttonLoader
                }
              >
                {" "}
                {((loading && value === "posMenuData") ||
                  (menuData.menuList === null &&
                    menuData.subMenuList === null &&
                    menuData.catalogueList === null)) &&
                  buttonLoader ? (
                  <LoadingSpinner color="#ffffff" size={30} type={"TailSpin"} />
                ) : (
                  "Sync Branch Data"
                )}
              </Button> */}
            </>
          )}
        </div>

        {/* PRINT CONFIGURATION BUTTON */}
        <Button type="button" variant="default" className="btn-icon outline-none me-3" onClick={() => handlePrintConfiguration(true)}>
          <i className="ri-printer-line fs-3"></i>
        </Button>

        {/* SYNC BRANCHES BUTTON */}
        <Button
          type="button"
          variant="default"
          className="btn-icon outline-none"
          size="sm"
          onClick={() => {
            setButtonLoader(true);
            dispatch(GetPOSMenuDataByBranchId(selectedOptions?.value));
          }}
          disabled={
            ((loading && value === "posMenuData") ||
              (menuData.menuList === null &&
                menuData.subMenuList === null &&
                menuData.catalogueList === null)) &&
            buttonLoader
          }
        >
          {" "}
          {((loading && value === "posMenuData") ||
            (menuData.menuList === null &&
              menuData.subMenuList === null &&
              menuData.catalogueList === null)) &&
            buttonLoader ? (
            <i className="ri-refresh-line sync-animation fs-3"></i>
          ) : (
            <i className="ri-refresh-line fs-3"></i>
          )}
        </Button>

        {/* SKIN MODE DARK AND LIGHT */}
        <Dropdown className="dropdown-skin ms-3" align="end">
          <Dropdown.Toggle as={CustomToggle}>
            <i className="ri-settings-3-line"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu className="mt-10-f">
            <label className="text-dark fs-12 mb-1">Skin Mode</label>
            <nav className="nav nav-skin">
              <Link
                onClick={skinMode}
                className={
                  localStorage.getItem("skin-mode")
                    ? "nav-link"
                    : "nav-link active"
                }
              >
                Light
              </Link>
              <Link
                onClick={skinMode}
                className={
                  localStorage.getItem("skin-mode")
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                Dark
              </Link>
            </nav>

          </Dropdown.Menu>
        </Dropdown>

        {/* NOTIFICATION DROP DOWN */}
        <Dropdown className="dropdown-notification ms-3 ms-xl-4" align="end">
          <Dropdown.Toggle as={CustomToggle}>
            <small>3</small>
            <i className="ri-notification-3-line"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu className="mt-10-f me--10-f">
            <div className="dropdown-menu-header">
              <h6 className="dropdown-menu-title">Notifications</h6>
            </div>
            {NotificationList()}
            <div className="dropdown-menu-footer">
              <Link to="#">Show all Notifications</Link>
            </div>
          </Dropdown.Menu>
        </Dropdown>

        {/* PROFILE DROP DOWN */}
        <Dropdown className="dropdown-profile ms-3 ms-xl-4" align="end">
          <Dropdown.Toggle as={CustomToggle}>
            <div className="avatar online">
              <div className="user-profile">
                <i className="ri-user-fill"></i>
              </div>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className="mt-10-f">
            <div className="dropdown-menu-body">
              <div className="avatar avatar-xl online mb-3">
                <div className="user-profile">
                  <i className="ri-user-fill fs-36"></i>
                </div>
              </div>
              <h5 className="mb-1 text-dark fw-semibold">Aamir Sayyed</h5>
              <p className="fs-sm text-secondary">Premium Member</p>

              <nav className="nav">
                <Link to=""><i className="ri-edit-2-line"></i> Edit Profile</Link>
                <Link to=""><i className="ri-profile-line"></i> View Profile</Link>
              </nav>
              <hr />
              <nav className="nav">
                <Link to=""><i className="ri-question-line"></i> Help Center</Link>
                <Link to=""><i className="ri-lock-line"></i> Privacy Settings</Link>
                <Link to="/login.fudeasy"><i className="ri-user-settings-line"></i> Login Your Bussiness ID</Link>
                <Link to="/account/login" onClick={() => {logout();}}><i className="ri-logout-box-r-line"></i> Log Out</Link>
              </nav>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </React.Fragment>
  );
}

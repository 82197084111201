import tableMiddleware from './tableMiddleware';
import taxMiddleware from './taxMiddleware';
import discountMiddleware from './discountMiddleware';
import menuMiddleware from './menuMiddleware';
import loaderMiddleware from './loaderMiddleware';
import saleMiddleware from './saleMiddleware';
import itemMiddleware from './itemMiddleware';
import addOnsMiddleware from './addOnsMiddleware';
import kitchenMiddleware from './kitchenMiddleware';
import chargesMiddleware from './chargesMiddleware';
import varianceMiddleware from './varianceMiddleware';
import branchMiddleware from './branchMiddleware';
import orderTypeMiddleWare from './orderTypeMiddleWare';
import materialMiddleware from './materialMiddleware';
import receipeMiddleware from './receipeMiddleware';
import kotRemarkMiddleware from './kotRemarkMiddleware';
import employeeMiddleware from './employeeMiddleware';
import posMiddleware from './posMiddleware';
import customerMiddleware from './customerMiddleware';
import accountMiddleware from './accountMiddleware';
import userMiddleware from './userMiddleware';
import paymentModeMiddleware from './paymentModeMiddleware';
import generalSettingMiddleware from './generalSettingMiddleware';
import salesOrdersMiddleware from './salesOrdersMiddleware';
import printTemplateMiddleware from './printTemplateMiddleware';
import recieptMiddleware from './recieptMiddleware';
import vendorMiddleware from './vendorMiddleware';
import integrationMiddleware from './integrationMiddleware';

export default [
    ...loaderMiddleware,
    ...saleMiddleware,
    ...tableMiddleware,
    ...itemMiddleware,
    ...menuMiddleware,
    ...addOnsMiddleware,
    ...kitchenMiddleware,
    ...taxMiddleware,
    ...discountMiddleware,
   ...chargesMiddleware,
   ...varianceMiddleware,
    ...branchMiddleware,
    ...orderTypeMiddleWare,
    ...materialMiddleware,
    ...receipeMiddleware,
    ...kotRemarkMiddleware,
    ...employeeMiddleware,
    ...posMiddleware,
    ...customerMiddleware,
    ...accountMiddleware,
    ...userMiddleware,
    ...paymentModeMiddleware,
    ...generalSettingMiddleware,
    ...salesOrdersMiddleware,
    ...printTemplateMiddleware,
    ...recieptMiddleware,
    ...vendorMiddleware,
    ...integrationMiddleware

]
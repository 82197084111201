export const WABA_BUSINESS_DETAILS = "WABA_BUSINESS_DETAILS";
export const WABA_BUSINESS_DETAILS_SUCCESS = "WABA_BUSINESS_DETAILS_SUCCESS";
export const WABA_PHONENUMBER_LIST_BRANCHMAP =
  "WABA_PHONENUMBER_LIST_BRANCHMAP";
export const WABA_PHONENUMBER_LIST_BRANCHMAP_SUCCESS =
  "WABA_PHONENUMBER_LIST_BRANCHMAP_SUCCESS";
export const PHONENUMBER_DETAILS_WITH_BRANCH_MAPPED =
  "PHONENUMBER_DETAILS_WITH_BRANCH_MAPPED";
export const PHONENUMBER_DETAILS_WITH_BRANCH_MAPPED_SUCCESS =
  "PHONENUMBER_DETAILS_WITH_BRANCH_MAPPED_SUCCESS";
export const SEND_WPPHONENUMBER_VERIFICATION_OTP =
  "SEND_WPPHONENUMBER_VERIFICATION_OTP";
export const SEND_WPPHONENUMBER_VERIFICATION_OTP_SUCCESS =
  "SEND_WPPHONENUMBER_VERIFICATION_OTP_SUCCESS";
export const ADD_PHONENUMBER = "ADD_PHONENUMBER";
export const VERIFY_OTP = "VERIFY_OTP";
export const MAP_UNMAP_BRANCH_WITH_PHONENUMBER = "MAP_UNMAP_BRANCH_WITH_PHONENUMBER";
export const REGISTER_PHONENUMBER = "REGISTER_PHONENUMBER";
export const MAP_UNMAP_BRANCH_WITH_PHONENUMBER_SUCCESS = "MAP_UNMAP_BRANCH_WITH_PHONENUMBER_SUCCESS";
export const REGISTER_PHONENUMBER_SUCCESS = "REGISTER_PHONENUMBER_SUCCESS";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";

export const wABABusinessDetails = (data) => ({
  type: WABA_BUSINESS_DETAILS,
  payload: data,
});
export const wABABusinessDetailsSuccess = (data) => ({
  type: WABA_BUSINESS_DETAILS_SUCCESS,
  payload: data,
});
export const wABAPhoneNumberListBranchMap = (data) => ({
  type: WABA_PHONENUMBER_LIST_BRANCHMAP,
  payload: data,
});
export const wABAPhoneNumberListBranchMapSuccess = (data) => ({
  type: WABA_PHONENUMBER_LIST_BRANCHMAP_SUCCESS,
  payload: data,
});
export const phoneNumberDetailsWithBranchMapped = (data) => ({
  type: PHONENUMBER_DETAILS_WITH_BRANCH_MAPPED,
  payload: data,
});
export const phoneNumberDetailsWithBranchMappedSuccess = (data) => ({
  type: PHONENUMBER_DETAILS_WITH_BRANCH_MAPPED_SUCCESS,
  payload: data,
});
export const sendWPPhoneNumberVerificationOtp = (data) => ({
  type: SEND_WPPHONENUMBER_VERIFICATION_OTP,
  payload: data,
});
export const verifyOTP = (data) => ({
  type: VERIFY_OTP,
  payload: data,
});
export const verifyOTPSuccess = (data) => ({
  type: VERIFY_OTP_SUCCESS,
  payload: data,
});
export const sendWPPhoneNumberVerificationOtpSuccess = (data) => ({
  type: SEND_WPPHONENUMBER_VERIFICATION_OTP_SUCCESS,
  payload: data,
});
export const addPhoneNumber = (data) => ({
  type: ADD_PHONENUMBER,
  payload: data,
});
export const mapUnMapBranchWithPhoneNumber = (data) => ({
  type: MAP_UNMAP_BRANCH_WITH_PHONENUMBER,
  payload: data,
});
export const mapUnMapBranchWithPhoneNumberSuccess = (data) => ({
  type: MAP_UNMAP_BRANCH_WITH_PHONENUMBER_SUCCESS,
  payload: data,
});
export const registerPhoneNumber = (data) => ({
  type: REGISTER_PHONENUMBER,
  payload: data,
});
export const registerPhoneNumberSuccess = (data) => ({
  type: REGISTER_PHONENUMBER_SUCCESS,
  payload: data,
});
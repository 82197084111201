import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  exchangeTokenForAccessToken,
  getWPFBDetails,
} from "../../../application/actions/posAction";
import { Button } from "react-bootstrap";
import facebookLogo from "../../../assets/img/integration/facebook.png";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";
import LoadingSpinner from "../../_common/LoadingSpinner";
import { getLoading } from "../../../application/selectors/indexSelector";

const FacebookLogin = () => {
  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);
  const selectedDetails = JSON.parse(localStorage.getItem("branch"));
  const contactDataRef = useRef({ phone_number_id: null, waba_id: null });
  const statusRef = useRef(null);
  const loading = useSelector(getLoading).loading;
  const value = useSelector(getLoading).value;
  const WPFBDetails = useSelector(getWPFBDetails);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");

    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "1006697420683568",
        autoLogAppEvents: true,
        xfbml: true,
        version: "v20.0",
      });
    };

    const handleMessage = (event) => {
      if (
        event.origin !== "https://www.facebook.com" &&
        event.origin !== "https://web.facebook.com"
      ) {
        return;
      }
      try {
        const data = JSON.parse(event.data);
        if (data.type === "WA_EMBEDDED_SIGNUP") {
          if (data.event === "FINISH") {
            const { phone_number_id, waba_id } = data.data;
            contactDataRef.current = { phone_number_id, waba_id };
            statusRef.current = { event: "FINISH", code: data.data.code };
          } else if (data.event === "CANCEL") {
            const { current_step } = data.data;
            setIsLoading(false);
            console.warn("Cancel at ", current_step);
          } else if (data.event === "ERROR") {
            const { error_message } = data.data;
            setIsLoading(false);
            console.error("error ", error_message);
          }
        } else {
          setIsLoading(false);
        }
      } catch {
        console.log("Non JSON Responses", event.data);
        setIsLoading(false);
      }
    };
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const fbLoginCallback = (response) => {
    setIsLoading(true);
    if (response.authResponse) {
      const code = response.authResponse.code;
      if (statusRef.current && statusRef.current.event === "FINISH") {
        const formObject = {
          tenantId: user?.tenanatId,
          branchId: selectedDetails?.value,
          appId: "1006697420683568",
          wabaId: contactDataRef.current?.waba_id,
          phoneNumberId: contactDataRef.current?.phone_number_id,
          accessToken: code,
          lastOnboardedTime: new Date(),
        };
        dispatch(exchangeTokenForAccessToken(formObject));
      }
    } else {
      console.error("Login failed");
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (WPFBDetails && isLoading) {
      setIsLoading(false);
    }
  }, [WPFBDetails]);

  const launchWhatsAppSignup = () => {
    window?.FB?.login(fbLoginCallback, {
      config_id: "8235427479854270",
      response_type: "code",
      override_default_response_type: true,
      // extras: {
      //   setup: {},
      //   featureType: "",
      //   sessionInfoVersion: "2",
      // },
      extras: {
        setup: {
          business: {
            name: "Fudeasy",
            email: "inayat.a@gmail.com",
          },
        },
        featureType: "",
        sessionInfoVersion: "2",
      },
    });
  };

  return (
    <div>
      <Button
        className="fs-12 text-uppercase"
        onClick={launchWhatsAppSignup}
        style={{
          color: "#1877F2",
          backgroundColor: "#fff",
          borderColor: "#1877F2",
        }}
        disabled={
          (loading &&
            value === "exchangeTokenForAccessToken" &&
            WPFBDetails !== undefined) ||
          isLoading
        }
      >
        {(loading &&
          value === "exchangeTokenForAccessToken" &&
          WPFBDetails !== undefined) ||
        isLoading ? (
          <LoadingSpinner color="#1877F2" size={20} type={"TailSpin"} />
        ) : (
          <>
            <img
              src={facebookLogo}
              alt="Facebook Logo"
              width={"20px"}
              className="mx-1"
            />
            <span className="fw-bold fs-14">Login with Facebook</span>
          </>
        )}
      </Button>

      <div id="fb-root"></div>
    </div>
  );
};

export default FacebookLogin;

export const DISCOUNT_LIST = 'DISCOUNT_LIST'
export const DISCOUNT_LIST_SUCCESS = 'DISCOUNT_LIST_SUCCESS'
export const ADD_DISCOUNT = 'ADD_DISCOUNT'
export const GET_DISCOUNT_BY_ID = 'GET_DISCOUNT_BY_ID'
export const GET_DISCOUNT_BY_ID_SUCCESS = 'GET_DISCOUNT_BY_ID_SUCCESS'
export const DELETE_DISCOUNT = 'DELETE_DISCOUNT'
export const MAP_DISCOUNT_ORDERTYPE = 'MAP_DISCOUNT_ORDERTYPE'
export const UNMAP_DISCOUNT_ORDERTYPE = 'UNMAP_DISCOUNT_ORDERTYPE'
export const MAP_DISCOUNT_WITH_BRANCH = 'MAP_DISCOUNT_WITH_BRANCH'
export const UNMAP_DISCOUNT_WITH_BRANCH = 'UNMAP_DISCOUNT_WITH_BRANCH'

export const GetDiscount = {
    type: DISCOUNT_LIST 
};
export const GetDiscountSuccess = (data)=>({
    type: DISCOUNT_LIST_SUCCESS,   
    payload:{data}
});
export const AddDiscount = (data)=>({
    type: ADD_DISCOUNT,   
    payload:{data}
});
export const GetDiscountById = (data)=>({
    type: GET_DISCOUNT_BY_ID,   
    payload:{data}
});
export const GetDiscountByIdSuccess = (data)=>({
    type: GET_DISCOUNT_BY_ID_SUCCESS,   
    payload:{data}
});
export const DeleteDiscount = (id)=>({
    type: DELETE_DISCOUNT,   
    payload:id
});
export const MapDiscountOrdertype = (data) => ({
    type: MAP_DISCOUNT_ORDERTYPE,
    payload: data,
  });
  export const UnMapDiscountOrdertype = (id) => ({
    type: UNMAP_DISCOUNT_ORDERTYPE,
    payload: id,
  });
  export const MapDiscountWithBranch = (data) => ({
    type: MAP_DISCOUNT_WITH_BRANCH,
    payload: data ,
  });
  export const UnMapDiscountWithBranch = (data) => ({
    type: UNMAP_DISCOUNT_WITH_BRANCH,
    payload: data,
  });
export const GET_KITCHEN_LIST = 'GET_KITCHEN_LIST'
export const GET_KITCHEN_LIST_SUCCESS = 'GET_KITCHEN_LIST_SUCCESS'
export const GET_KITCHEN_BY_ID = 'GET_KITCHEN_BY_ID'
export const CREATE_KITCHEN = 'CREATE_KITCHEN'
export const UPDATE_KITCHEN = 'UPDATE_KITCHEN'
export const DELETE_KITCHEN = 'DELETE_KITCHEN'
export const KITCHEN_MENU_MAPPING = 'KITCHEN_MENU_MAPPING'
export const KITCHEN_MENU_MAPPING_LIST = 'KITCHEN_MENU_MAPPING_LIST'
export const GET_KITCHEN_BY_ID_SUCCESS = 'GET_KITCHEN_BY_ID_SUCCESS'
export const UNMAP_KITCHEN_MENU = 'UNMAP_KITCHEN_MENU'

export const GetKitchenList = (data)=>({
    type: GET_KITCHEN_LIST,
    payload:{data}
 });
export const GetKitchenListSuccess = (data)=>({
    type: GET_KITCHEN_LIST_SUCCESS,
    payload:{data}
 });
export const GetKitchenById = (data)=>({
    type: GET_KITCHEN_BY_ID,
    payload:data
 });
 export const GetKitchenByIdSuccess = (data)=>({
   type: GET_KITCHEN_BY_ID_SUCCESS,
   payload:{data}
});
export const CreateKitchen   = (data)=>({
    type: CREATE_KITCHEN,
    payload:{data}
 });
export const UpdateKitchen   = (data)=>({
    type: UPDATE_KITCHEN,
    payload:{data}
 });
export const DeleteKitchen   = (data)=>({
    type: DELETE_KITCHEN,
    payload:{data}
 });
 export const MapKitchenMenu   = (data)=>({
    type: KITCHEN_MENU_MAPPING,
    payload:data
 });
 export const UnMapKitchenMenu   = (data)=>({
    type: UNMAP_KITCHEN_MENU,
    payload:data
 }); 
 


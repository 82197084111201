import Environment from "../../../core/Environment";
import jwtInterceptor from "../../../core/helpers/jwtInterceptor";

const storedToken = JSON.parse(localStorage.getItem("Token"));

const wABABusinessDetails = async (data) => {
  const { wabaId, accessToken } = data;
  const response = await jwtInterceptor.get(
    `${Environment.Integration_Url}get-waba-details`,
    {
      params: {
        wabaId: wabaId,
        accessToken: accessToken,
      },
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response.data;
};
const wABAPhoneNumberListBranchMap = async (data) => {
  const { tenantId, wabaId, accessToken } = data;
  const response = await jwtInterceptor.get(
    `${Environment.Integration_Url}get-phonenumbers-by-tenant-wp`,
    {
      params: {
        tenantId: tenantId,
        wabaId: wabaId,
        accessToken: accessToken,
      },
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response.data;
};
const phoneNumberDetailsWithBranchMapped = async (data) => {
  const { tenantId, phoneNumberId } = data;
  const response = await jwtInterceptor.get(
    `${Environment.Integration_Url}get-phoneNumber-details-with-branchmapped-list`,
    {
      params: {
        tenantId: tenantId,
        phoneNumberId: phoneNumberId,
      },
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response.data;
};
const sendWPPhoneNumberVerificationOtp = async (data) => {
  const response = await jwtInterceptor.post(
    `${Environment.Integration_Url}send-verification-code`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response.data;
};
const addPhoneNumber = async (data) => {
  const response = await jwtInterceptor.post(
    `${Environment.Integration_Url}add-phonenumber`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response.data;
};
const verifyOTP = async (data) => {
  const response = await jwtInterceptor.post(
    `${Environment.Integration_Url}verify-code`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response.data;
};
const mapUnMapBranchWithPhoneNumber = async (data) => {
  const { PhoneNumberId, BranchId, TenantId, IsChecked } = data;
  const formObject = {
    PhoneNumberId: PhoneNumberId,
    BranchId: BranchId,
    TenantId: TenantId,
    IsChecked: IsChecked,
  };
  const response = await jwtInterceptor.post(
    `${Environment.Integration_Url}map-unmap-phonenumber-branch`,
    formObject,
    {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response.data;
};
const registerPhoneNumber = async (data) => {
  const response = await jwtInterceptor.post(
    `${Environment.Integration_Url}register-phonenumber`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response.data;
};

export default {
  wABABusinessDetails,
  wABAPhoneNumberListBranchMap,
  phoneNumberDetailsWithBranchMapped,
  sendWPPhoneNumberVerificationOtp,
  addPhoneNumber,
  verifyOTP,
  mapUnMapBranchWithPhoneNumber,
  registerPhoneNumber
};

import React, { useContext, useEffect, useState } from "react";
import { Button, Offcanvas, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getLoading,
  getphoneNumberDetailsWithBranchMappedList,
  getwabaDetails,
  getwabaPhoneNumberList,
} from "../../../application/selectors/indexSelector";
import DataTable from "react-data-table-component";
import TableShimmerLoader from "../../../layouts/ShimmerTable";
import { phoneNumberDetailsWithBranchMapped } from "../../../application/actions/integrationAction";
import PhoneNumberDetailsManage from "./PhoneNumberDetailsManage";
import AddPhoneNumberDetails from "./AddPhoneNumberDetails";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";
import LoadingSpinner from "../../_common/LoadingSpinner";

function WhatsappManage(props) {
  const dispatch = useDispatch();
  const wabaDetails = useSelector(getwabaDetails);
  const wabaPhoneNumberList = useSelector(getwabaPhoneNumberList);
  const loading = useSelector(getLoading).loading;
  const value = useSelector(getLoading).value;
  const [isLoading, setIsLoading] = useState(false);
  const phoneNumberDetailsWithBranchMappedList = useSelector(
    getphoneNumberDetailsWithBranchMappedList
  );
  const { user } = useContext(AuthContext);
  const [isEditPhoneNumberModalOpen, setIsEditPhoneNumberModalOpen] =
    useState(false);
  const [isAddPhoneNumberModalOpen, setIsAddPhoneNumberModalOpen] =
    useState(false);
  const columns = [
    {
      name: "Id",
      selector: (row) => (
        <h6 className="fs-13 fw-semibold">{row.phoneNumberId}</h6>
      ),
    },
    {
      name: "Display Name",
      selector: (row) => (
        <h6 className="fs-13 fw-semibold">{row.verifiedName}</h6>
      ),
    },
    {
      name: "Phone Number",
      selector: (row) => <h5 className="fs-12 ">{row.displayPhoneNumber}</h5>,
    },
    {
      name: "Branch Mapped Count",
      selector: (row) => (
        <h6 className="fs-13 text-center">{row.branchCount}</h6>
      ),
    },
    {
      name: "Status",
      selector: (row) => (
        <h6 className="fs-12 fw-semibold">
          <span
            class={`badge bg-${
              row.codeVerificationStatus === "VERIFIED" ? "success" : "danger"
            }`}
          >
            {row.codeVerificationStatus === "VERIFIED"
              ? "Verified"
              : "Not Verified"}
          </span>
        </h6>
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <div>
          <div className="w-100">
            <div className={`d-flex justify-content-end`}>
              <Button
                variant="primary"
                className="btn-icon me-2"
                onClick={() => handleEditPhoneNumber(row)}
                disabled={
                  (loading &&
                    value === "phoneNumberDetailsWithBranchMapped" &&
                    phoneNumberDetailsWithBranchMappedList === undefined) ||
                  isLoading
                }
              >
                {(loading &&
                  value === "phoneNumberDetailsWithBranchMapped" &&
                  phoneNumberDetailsWithBranchMappedList === undefined) ||
                isLoading ? (
                  <LoadingSpinner color="#ffffff" size={15} type={"TailSpin"} />
                ) : (
                  <i className="ri-pencil-line"></i>
                )}
              </Button>
            </div>
          </div>
        </div>
      ),
    },
  ];
  const handleEditPhoneNumber = (data) => {
    setIsLoading(true);
    const formObject = {
      tenantId: user?.tenanatId,
      phoneNumberId: data?.id,
    };
    dispatch(phoneNumberDetailsWithBranchMapped(formObject));
  };
  useEffect(() => {
    if (phoneNumberDetailsWithBranchMappedList && isLoading) {
      setIsEditPhoneNumberModalOpen(true);
      setIsLoading(false);
    }
  }, [phoneNumberDetailsWithBranchMappedList]);
  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-50"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">
            Whatsapp Manage
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="mx-3">
          <Row>
            <h6>
              Account Name:
              <span className="fw-bolder">
                {" "}
                {wabaDetails?.wpBusinessName}
              </span>{" "}
            </h6>
          </Row>
          <div className="custom-common-table mt-4">
            <div className="d-flex justify-content-between">
              <div>
                <h6 className="fs-15 fw-semibold">Phone Numbers</h6>
              </div>
              <div className="">
                <Button
                  variant="primary"
                  onClick={() => setIsAddPhoneNumberModalOpen(true)}
                >
                  Add Phone Number
                </Button>
              </div>
            </div>
            {loading &&
            (value === "wABAPhoneNumberListBranchMap" ||
              value === "addPhoneNumber") ? (
              <TableShimmerLoader columnCount={4} rowCount={4} />
            ) : (
              <DataTable
                columns={columns}
                data={wabaPhoneNumberList}
                fixedHeader
                search={true}
                highlightOnHover
                pagination
              ></DataTable>
            )}
          </div>
        </Offcanvas.Body>
        <div className="offcanvas-footer justify-content-start"></div>
      </Offcanvas>
      <PhoneNumberDetailsManage
        show={isEditPhoneNumberModalOpen}
        closeFunction={() => setIsEditPhoneNumberModalOpen(false)}
      />
      <AddPhoneNumberDetails
        show={isAddPhoneNumberModalOpen}
        closeFunction={() => setIsAddPhoneNumberModalOpen(false)}
      />
    </React.Fragment>
  );
}

export default WhatsappManage;

import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Offcanvas, Row } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "flag-icon-css/css/flag-icon.min.css";
import { useDispatch, useSelector } from "react-redux";
import { addPhoneNumber } from "../../../application/actions/integrationAction";
import Environment from "./../../../infrastructure/core/Environment";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";
import { getLoading } from "../../../application/selectors/indexSelector";
import LoadingSpinner from "../../_common/LoadingSpinner";

function AddPhoneNumberDetails(props) {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [phone, setPhone] = useState("");
  const wpfbDetails = JSON.parse(localStorage.getItem("wpfbDetails"));
  const loading = useSelector(getLoading).loading;
  const value = useSelector(getLoading).value;
  const { user } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = () => {
    setIsLoading(true);
    const flagElement = document.querySelector(".selected-flag");
    let countryCode = "";
    let selectedCountry = "";

    if (flagElement) {
      const title = flagElement.getAttribute("title");
      if (title) {
        selectedCountry = title.split(":")[1]?.trim();
        countryCode = selectedCountry.replace(/\D/g, "");
      }
    }
    const phoneNumberWithoutCountryCode = phone
      .replace(`+${countryCode}`, "")
      .replace(/[^0-9]/g, "")
      .replace(countryCode, "");

    const formObject = {
      CountryCode: countryCode,
      PhoneNumber: phoneNumberWithoutCountryCode,
      VerifiedName: formData.VerifiedName,
      wabaId: wpfbDetails.wabaId,
      AccessToken: wpfbDetails.accessToken,
      BranchId: Environment.defaultValue,
      TenantId: user?.tenanatId,
    };
    dispatch(addPhoneNumber(formObject));
  };
  useEffect(() => {
    if (loading && value === "addPhoneNumber" && isLoading) {
      setIsLoading(false);
      setFormData({});
      setPhone("");
      props.closeFunction();
    }
  }, [loading, value]);

  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-30"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">
            Add Phone Number
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row className="mx-4">
            <div className="mb-4">
              <Form.Label>
                Phone Number: <sup className="text-danger">*</sup>
              </Form.Label>
              <PhoneInput
                country={"in"}
                value={phone}
                onChange={setPhone}
                containerClass="phone-input-container"
                buttonClass="flag-dropdown"
                enableSearch
                isValid={(value, country) => {
                  if (value.match(/12345/)) {
                    return "Invalid value: " + value + ", " + country.name;
                  } else if (value.match(/1234/)) {
                    return false;
                  } else {
                    return true;
                  }
                }}
              />
            </div>
            <div className="mb-4">
              <Form.Label>
                Verified Name: <sup className="text-danger">*</sup>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Verified Name"
                name="VerifiedName"
                onChange={(e) =>
                  setFormData({ ...formData, VerifiedName: e.target.value })
                }
              />
            </div>
          </Row>
        </Offcanvas.Body>
        <div className="offcanvas-footer justify-content-start">
          <div className="mb-4">
            <Button
              variant="primary"
              className="btn me-2"
              onClick={handleSubmit}
              disabled={(loading && value === "addPhoneNumber") || isLoading}
            >
              {(loading && value === "addPhoneNumber") || isLoading ? (
                <LoadingSpinner color="#ffffff" size={15} type={"TailSpin"} />
              ) : (
                "Add"
              )}
            </Button>
          </div>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}

export default AddPhoneNumberDetails;

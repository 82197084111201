import { Navigate } from "react-router-dom";
import * as Loading from "../actions/loaderAction";
import ToastifyService from "../../View/_common/ToastifyService";
import {
  ADD_PHONENUMBER,
  MAP_UNMAP_BRANCH_WITH_PHONENUMBER,
  mapUnMapBranchWithPhoneNumberSuccess,
  PHONENUMBER_DETAILS_WITH_BRANCH_MAPPED,
  phoneNumberDetailsWithBranchMappedSuccess,
  REGISTER_PHONENUMBER,
  registerPhoneNumberSuccess,
  SEND_WPPHONENUMBER_VERIFICATION_OTP,
  sendWPPhoneNumberVerificationOtpSuccess,
  VERIFY_OTP,
  verifyOTPSuccess,
  WABA_BUSINESS_DETAILS,
  WABA_PHONENUMBER_LIST_BRANCHMAP,
  wABABusinessDetailsSuccess,
  wABAPhoneNumberListBranchMapSuccess,
} from "../actions/integrationAction";
import { GetBranchSuccess } from "../actions/branchAction";

const wABABusinessDetails =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === WABA_BUSINESS_DETAILS) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "wABABusinessDetails" })
        );
        const wABABusinessDetail = await api.integrationAPI.wABABusinessDetails(
          action.payload
        );
        dispatch(wABABusinessDetailsSuccess(wABABusinessDetail));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(
          Loading.setLoading({ loading: false, value: "wABABusinessDetails" })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({ loading: false, value: "wABABusinessDetails" })
        );
      }
    }
    next(action);
  };
const wABAPhoneNumberListBranchMap =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === WABA_PHONENUMBER_LIST_BRANCHMAP) {
      try {
        dispatch(
          Loading.setLoading({
            loading: true,
            value: "wABAPhoneNumberListBranchMap",
          })
        );
        const wABABusinessDetail =
          await api.integrationAPI.wABAPhoneNumberListBranchMap(action.payload);
        dispatch(wABAPhoneNumberListBranchMapSuccess(wABABusinessDetail));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(
          Loading.setLoading({
            loading: false,
            value: "wABAPhoneNumberListBranchMap",
          })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({
            loading: false,
            value: "wABAPhoneNumberListBranchMap",
          })
        );
      }
    }
    next(action);
  };
const phoneNumberDetailsWithBranchMapped =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === PHONENUMBER_DETAILS_WITH_BRANCH_MAPPED) {
      try {
        dispatch(
          Loading.setLoading({
            loading: true,
            value: "phoneNumberDetailsWithBranchMapped",
          })
        );
        const phoneNumberDetailsWithBranchMappedList =
          await api.integrationAPI.phoneNumberDetailsWithBranchMapped(
            action.payload
          );
        dispatch(
          phoneNumberDetailsWithBranchMappedSuccess(
            phoneNumberDetailsWithBranchMappedList
          )
        );
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(
          Loading.setLoading({
            loading: false,
            value: "phoneNumberDetailsWithBranchMapped",
          })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({
            loading: false,
            value: "phoneNumberDetailsWithBranchMapped",
          })
        );
      }
    }
    next(action);
  };
const sendWPPhoneNumberVerificationOtp =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === SEND_WPPHONENUMBER_VERIFICATION_OTP) {
      try {
        dispatch(
          Loading.setLoading({
            loading: true,
            value: "sendWPPhoneNumberVerificationOtp",
          })
        );
        const wPFbABusinessDetail =
          await api.integrationAPI.sendWPPhoneNumberVerificationOtp(
            action.payload
          );
        dispatch(wABAPhoneNumberListBranchMapSuccess(wPFbABusinessDetail));
      } catch (error) {
        ToastifyService.error(error?.message);
        dispatch(
          Loading.setLoading({
            loading: false,
            value: "sendWPPhoneNumberVerificationOtp",
          })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({
            loading: false,
            value: "sendWPPhoneNumberVerificationOtp",
          })
        );
      }
    }
    next(action);
  };
const addPhoneNumber =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === ADD_PHONENUMBER) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "addPhoneNumber" })
        );
        const wABABusinessDetail = await api.integrationAPI.addPhoneNumber(
          action.payload
        );
        dispatch(wABAPhoneNumberListBranchMapSuccess(wABABusinessDetail));
      } catch (error) {
        console.error("error", error);
        ToastifyService.error("Something went wrong");
        dispatch(
          Loading.setLoading({ loading: false, value: "addPhoneNumber" })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({ loading: false, value: "addPhoneNumber" })
        );
      }
    }
    next(action);
  };
const mapUnMapBranchWithPhoneNumber =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === MAP_UNMAP_BRANCH_WITH_PHONENUMBER) {
      try {
        dispatch(
          Loading.setLoading({
            loading: true,
            value: "mapUnMapBranchWithPhoneNumber",
          })
        );
        const phoneNumberDetailsWithBranchMapped =
          await api.integrationAPI.mapUnMapBranchWithPhoneNumber(
            action.payload
          );
        dispatch(
          phoneNumberDetailsWithBranchMappedSuccess(
            phoneNumberDetailsWithBranchMapped
          )
        );
        const formData = {
          wabaId: action.payload?.wabaId,
          accessToken: action.payload?.accessToken,
          tenantId: action.payload.TenantId,
        };
        const wABABusinessDetail =
          await api.integrationAPI.wABAPhoneNumberListBranchMap(formData);
        dispatch(wABAPhoneNumberListBranchMapSuccess(wABABusinessDetail));
        const BranchData = await api.branchAPI.GetBranchList(
          action.payload.TenantId
        );
        dispatch(GetBranchSuccess(BranchData));
        dispatch(mapUnMapBranchWithPhoneNumberSuccess(true));
      } catch (error) {
        console.error("error", error);
        ToastifyService.error(error?.error_user_title);
        dispatch(
          Loading.setLoading({
            loading: false,
            value: "mapUnMapBranchWithPhoneNumber",
          })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({
            loading: false,
            value: "mapUnMapBranchWithPhoneNumber",
          })
        );
      }
    }
    next(action);
  };
const verifyOTP =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === VERIFY_OTP) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "verifyOTP" }));
        const verifyOTPRes = await api.integrationAPI.verifyOTP(action.payload);
        const formObject = {
          tenantId: action?.payload?.TenantId,
          phoneNumberId: action?.payload?.Id,
          accessToken: action?.payload?.AccessToken,
        };
        const phoneNumberDetailsWithBranchMappedList =
          await api.integrationAPI.phoneNumberDetailsWithBranchMapped(
            formObject
          );
        dispatch(
          phoneNumberDetailsWithBranchMappedSuccess(
            phoneNumberDetailsWithBranchMappedList
          )
        );
        dispatch(wABABusinessDetailsSuccess(verifyOTPRes));
        const formData = {
          wabaId: action.payload?.wabaId,
          accessToken: action.payload?.AccessToken,
        };
        const wABABusinessDetail = await api.integrationAPI.wABABusinessDetails(
          formData
        );
        dispatch(wABABusinessDetailsSuccess(wABABusinessDetail));
        dispatch(verifyOTPSuccess(true));
      } catch (error) {
        ToastifyService.error(error?.message);
        dispatch(Loading.setLoading({ loading: false, value: "verifyOTP" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(Loading.setLoading({ loading: false, value: "verifyOTP" }));
      }
    }
    next(action);
  };
  const registerPhoneNumber =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === REGISTER_PHONENUMBER) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "registerPhoneNumber" }));
        const phoneNumberDetailsWithBranchMappedList = await api.integrationAPI.registerPhoneNumber(action.payload);
        dispatch(
          phoneNumberDetailsWithBranchMappedSuccess(
            phoneNumberDetailsWithBranchMappedList
          )
        );
        dispatch(registerPhoneNumberSuccess(true));
      } catch (error) {
        ToastifyService.error(error?.message);
        dispatch(Loading.setLoading({ loading: false, value: "registerPhoneNumber" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(Loading.setLoading({ loading: false, value: "registerPhoneNumber" }));
      }
    }
    next(action);
  };
export default [
  wABABusinessDetails,
  wABAPhoneNumberListBranchMap,
  phoneNumberDetailsWithBranchMapped,
  sendWPPhoneNumberVerificationOtp,
  addPhoneNumber,
  verifyOTP,
  mapUnMapBranchWithPhoneNumber,
  registerPhoneNumber
];

import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import bg1 from "../../assets/img/login/side-patch.png";
import whiteLogo from "../../assets/img/login/fe-white-logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changePassword, changePasswordSuccess } from "../../application/actions/accountAction";
import { getChangePasswordResponse, getLoading } from "../../application/selectors/indexSelector";
import LoadingSpinner from "../_common/LoadingSpinner";

export default function ResetPasswordComponent() {
  const changePasswordRes = useSelector(getChangePasswordResponse);
  const loading = useSelector(getLoading).loading;
  const value = useSelector(getLoading).value;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [formData, setFormData] = useState({
    // oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({
    // oldPassword: "",
    confirmPassword: "",
  });

  useEffect(() => {
    if (changePasswordRes) {
      dispatch(changePasswordSuccess(false));
      navigate("/account/Choose-branch");
    }
  }, [changePasswordRes]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    // Clear errors as the user types
    // if (name === "oldPassword" && value === state.password) {
    //   setErrors((prevErrors) => ({ ...prevErrors, oldPassword: "" }));
    // }
    if (name === "confirmPassword" && value === formData.newPassword) {
      setErrors((prevErrors) => ({ ...prevErrors, confirmPassword: "" }));
    }
  };

  const handleOnBlur = () => {
    const newErrors = {};
    // if (formData.oldPassword !== state.password) {
    //   newErrors.oldPassword = "Old password is incorrect";
    // }
    if (formData.newPassword !== formData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }
    setErrors(newErrors);
  };

  const handleSubmit = () => {
    const newErrors = {};
    // if (formData.oldPassword !== state.password) {
    //   newErrors.oldPassword = "Old password is incorrect";
    // }
    if (formData.newPassword !== formData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const formObject = {
      tenantId: state?.tenanatId,
      userId: state?.id,
      oldPass: state.password,
      newPass: formData?.newPassword,
    };
    dispatch(changePassword(formObject));
  };

  return (
    <div className="page-sign d-block py-0">
      <Row className="g-0">
        <Col md="7" lg="4" className="col-wrapper">
          <Card className="card-sign">
            <Card.Header>
              <Card.Title>Set your Password</Card.Title>
            </Card.Header>
            <Card.Body>
              {/* <div className="mb-3">
                <Form.Label>Current Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter Current Password"
                  autoComplete="off"
                  name="oldPassword"
                  onChange={handleChange}
                  onBlur={handleOnBlur}
                />
                {errors.oldPassword && (
                  <Form.Text className="text-danger">
                    {errors.oldPassword}
                  </Form.Text>
                )}
              </div> */}
              <div className="mb-3">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter New Password"
                  autoComplete="off"
                  name="newPassword"
                  onChange={handleChange}
                  onBlur={handleOnBlur}
                />
              </div>
              <div className="mb-3">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter Confirm Password"
                  autoComplete="off"
                  name="confirmPassword"
                  onChange={handleChange}
                  onBlur={handleOnBlur}
                />
                {errors.confirmPassword && (
                  <Form.Text className="text-danger">
                    {errors.confirmPassword}
                  </Form.Text>
                )}
              </div>
              <Button type="submit" variant="primary" className="btn-sign" disabled={loading && value === "resetPassword"} onClick={handleSubmit}> 
                  {loading && value==="resetPassword" ? (
                    <LoadingSpinner
                      color="#ffffff"
                      size={30}
                      type={"TailSpin"}
                    />
                  ) : (
                    "Change Password"
                  )}
                </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col className="d-none d-lg-block">
          <div className="login-msg">
            <div className="header-logo mb-3">
              <img className="wt-200" src={whiteLogo} alt="logo" />
            </div>
            <h1 className="text-white fw-semibold">
              Robust solution for <br /> smart & growing <br /> restaurant
            </h1>
          </div>
          <img src={bg1} className="h-100 w-100" alt="bg" />
        </Col>
      </Row>
    </div>
  );
}

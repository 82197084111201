import React, { useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import { Button, Offcanvas, Row, Col, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { UpdateItemCatalog } from "../../../application/actions/ItemAction";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";
import environment from "../../../infrastructure/core/Environment";
import { restrictAllNumbers, restrictAllSpecialCharacters, restrictConsecutiveSpace, validateRequired, validateRequiredDropdown } from "../../../infrastructure/core/validationUtils";
import Select from "react-select";

export default function EditDetails(props) {
  const { user } = useContext(AuthContext);
  const selectDaysOptions = [
    { value: 0, label: "Sunday" },
    { value: 1, label: "Monday" },
    { value: 2, label: "Tuesday" },
    { value: 3, label: "Wednesday" },
    { value: 4, label: "Thursday" },
    { value: 5, label: "Friday" },
    { value: 6, label: "Saturday" },
  ];
  const initialState = {
    id: "",
    catalogueName: "",
    catalogueCode: "",
    description: "",
    foodType: "",
    foodSubType: "",
    isKOT: 0,
    isDiscountAllowed: 0,
    createdBy: user.id,
    modifiedBy: user.id,
    visibility: props?.Catalogue?.visibility,
    status: props?.Catalogue?.status,
    isDaySpecial: props?.Catalogue?.isDaySpecial,
    addCatalogueOnDays: [],
  };
  const [formData, setFormData] = useState(initialState);

  useEffect(() => {
    Prism.highlightAll();
  }, []);
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.show) {
      if (props.data) {
        if (
          props.data.catalogue.foodType ===
          "a634316f-857d-41fd-a74c-895f61e4de65"
        ) {
          setShowSubType(true);
        } else {
          setShowSubType(false);
        }
        setFormData({
          ...formData,
          id: props.data.catalogue.id,
          catalogueName: props.data.catalogue.catalogueName,
          catalogueCode: props.data.catalogue.catalogueCode,
          description: props.data.catalogue.description,
          foodType: props.data.catalogue.foodType,
          foodSubType: props.data.catalogue.foodSubType,
          isKOT: props.data.catalogueFeatures.isKOT,
          isDiscountAllowed: props.data.catalogueFeatures.isDiscountAllowed,
          mainCatId: props.data.catalogue.mainCatId,
          catId: props.data.catalogue.catId,
          visibility: props?.Catalogue?.visibility,
          status: props?.Catalogue?.status,
          isDaySpecial: props?.Catalogue?.isDaySpecial,
          addCatalogueOnDays: props?.data?.catalogueOnDays?.map((days) => ({
            value: days.availableDay,
            label: days.dayName,
          })),
        });
      }
    }
  }, [props.data, props.show]);
  const [errors, setErrors] = useState({
    catalogueName: "",
    catalogueCode: "",
    mainCatId: "",
    catId: "",
    foodtype: "",
    foodSubType: "",
  });
  const validateInput = () => {
    const newErrors = {};
    // Validate catalogueName
    newErrors.catalogueName = validateRequired(formData.catalogueName)
      ? ""
      : "Catalogue Name is required";

    // Validate catalogueCode
    newErrors.catalogueCode = validateRequired(formData.catalogueCode)
      ? ""
      : "Short Code is required";
    // Validate foodtype (dropdown)
    newErrors.foodtype = validateRequiredDropdown(formData.foodtype)
      ? ""
      : "Please Select Food Type";
    if (formData.foodtype === "a634316f-857d-41fd-a74c-895f61e4de65") {
      // Validate foodSubType (dropdown)
      newErrors.foodSubType = validateRequiredDropdown(formData.foodSubType)
        ? ""
        : "Please Select Food Sub Type";
    }
    setErrors(newErrors);
    // Check if any field has errors
    return !Object.values(newErrors).some((error) => error);
  };
  const OnSubmit = (e) => {
    e.preventDefault();
    //const codeExists = props?.CatalogueCodes?.includes(formData.catalogueCode);
    // Validate the form
    const isValid = validateInput();
    if (!isValid) {
      return;
    }
    // if (codeExists && formData.catalogueCode) {
    //   setErrors({ ...errors, catalogueCode: "Item Short Code Already Exist" });
    // } else {
    //   setErrors({ ...errors, catalogueCode: "" });

    const selectedDays = new Set(
      formData.addCatalogueOnDays.map((day) => day.value)
    );
    const removedDays = props?.data?.catalogueOnDays?.filter(
      (day) => !selectedDays.has(day.availableDay)
    );
    const removedDaysIds = removedDays.map((day) => day.id);
    const newDataDays = props?.data?.catalogueOnDays?.map(
      (day) => day.availableDay
    );
    formData.addCatalogueOnDays = formData.addCatalogueOnDays.filter(
      (day) => !newDataDays.includes(day.value)
    );
    const formObject = {
      id: formData.id,
      catalogueName: formData.catalogueName,
      catalogueCode: formData.catalogueCode,
      description: formData.description,
      foodType: formData.foodType,
      foodSubType: formData.foodSubType,
      isKOT: formData.isKOT,
      isDiscountAllowed: formData.isDiscountAllowed,
      createdBy: user.id,
      modifiedBy: user.id,
      visibility: formData.visibility,
      status: formData.status,
      mainCatId: formData.mainCatId,
      catId: formData.catId,
      isDaySpecial: formData.isDaySpecial,
      addCatalogueOnDays: formData.isDaySpecial
        ? formData.addCatalogueOnDays.length === 0
          ? selectDaysOptions.map((days) => days.value)
          : formData.addCatalogueOnDays.map((days) => days.value)
        : formData.addCatalogueOnDays.map((days) => days.value),
      removeCatalogueOnDays: formData.isDaySpecial
        ? removedDaysIds
        : props?.data?.catalogueOnDays?.map((day) => day.id),
    };
    dispatch(UpdateItemCatalog(formObject));
    setFormData(initialState);
    props.closeFunction();
    //}
  };
  const [showSubType, setShowSubType] = useState(false);
  const handleFoodType = (e) => {
    if (e.target.value === "a634316f-857d-41fd-a74c-895f61e4de65") {
      setShowSubType(true);
    } else {
      setShowSubType(false);
    }
    setFormData({
      ...formData,
      foodType: e.target.value,
      foodSubType: `${environment.defaultValue}`,
    });
    setErrors((prevErrors) => ({ ...prevErrors, foodType: "" }));
  };
  const FoodType = JSON.parse(localStorage.getItem("CommonFields")).filter(
    (item) => item.fieldTypeCode === "FOODTY"
  );
  const FoodSubType = JSON.parse(localStorage.getItem("CommonFields")).filter(
    (item) => item.fieldTypeCode === "FUDSUBTY"
  );
  const handleCodeExist = (code) => {
    // setFormData({ ...formData, catalogueCode: code.target.value });
    const codeExists = props?.CatalogueCodes.includes(code);
    if (codeExists && code) {
      setErrors({ ...errors, catalogueCode: "Item Short Code Already Exist" });
    } else {
      setErrors({ ...errors, catalogueCode: "", catalogueName: "" });
    }
  };
  const generateShortCode = (name) => {
    const words = name.split(" ");
    const initials = words.map((word) => word.charAt(0));
    return initials.join("").toUpperCase();
  };

  const handleNameChange = (e) => {
    const name = e.target.value;
    const shortCode = generateShortCode(name);
    setFormData({ ...formData, catalogueName: name, catalogueCode: shortCode });
    setErrors({ ...errors, catalogueName: "" });
    handleCodeExist(shortCode);
  };

  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-40"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Edit Details</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col xl="6">
              <div className="mb-4">
                <Form.Label>
                  Item Name: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Item Name"
                  name="mainCatName"
                  value={formData.catalogueName}
                  onChange={handleNameChange}
                  onKeyDown={(e) => {
                    restrictConsecutiveSpace(e);
                    restrictAllSpecialCharacters(e);
                    restrictAllNumbers(e);
                  }}                />
                <Form.Text className="text-danger">
                  {errors.catalogueName}
                </Form.Text>
              </div>
            </Col>

            <Col xl="6">
              <div className="mb-4">
                <Form.Label>Item Short Code</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Item Short Code"
                  name="catalogueCode"
                  value={formData.catalogueCode}
                  onChange={(e) =>
                    setFormData({ ...formData, catalogueCode: e.target.value })
                  }
                  onBlur={() => handleCodeExist(formData.catalogueCode)}
                  onKeyDown={restrictConsecutiveSpace}
                />
                <Form.Text className="text-danger">
                  {errors.catalogueCode}
                </Form.Text>
              </div>
            </Col>
          </Row>


          <Row>
          <Col xl="12">
              <div className="mb-4">
                <Form.Label>Short Description</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Short Description"
                  value={formData.description}
                  name="description"
                  onChange={(e) => {
                    setFormData({ ...formData, description: e.target.value });
                  }}
                  onKeyDown={restrictConsecutiveSpace}
                />
                <Button variant="outline-primary" className="mt-2 magic-bttn">
                  <i className="ri-magic-line align-middle me-1"></i> 
                <span className="align-middle fs-12">Generate</span></Button>
              </div>
            </Col>
            <Col xl="12">
              <div className="mb-4">
                <Form.Label>Long Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  placeholder="Enter Description"
                  name="description"
                  value={formData.description}
                  onChange={(e) =>
                    setFormData({ ...formData, description: e.target.value })
                  }
                  onKeyDown={restrictConsecutiveSpace}
                />

              <Button variant="outline-primary" className="mt-2 magic-bttn">
                  <i className="ri-magic-line align-middle me-1"></i> 
                <span className="align-middle fs-12">Generate</span></Button>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl="6">
              <Form.Label>
                Food Type: <sup className="text-danger">*</sup>
              </Form.Label>
              <div className="mb-4">
                <Form.Select
                  aria-label="Default select example"
                  value={formData.foodType}
                  onChange={handleFoodType}
                >
                  <option value={`${environment.defaultValue}`} disabled>
                    Food Type
                  </option>
                  {FoodType?.map((option, index) => (
                    <option key={index} value={option.id}>
                      {option.fieldValue}
                    </option>
                  ))}
                </Form.Select>
                <Form.Text className="text-danger">{errors.foodType}</Form.Text>
              </div>
            </Col>
            {showSubType && (
              <Col xl="6">
                <Form.Label>
                  Sub Food Type: <sup className="text-danger">*</sup>
                </Form.Label>
                <div className="mb-4">
                  <Form.Select
                    aria-label="Default select example"
                    value={formData.foodSubType}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        foodSubType: e.target.value,
                      });
                    }}
                  >
                    <option value={`${environment.defaultValue}`} disabled>
                      Sub Food Type
                    </option>
                    {FoodSubType?.map((option, index) => (
                      <option key={index} value={option.id}>
                        {option.fieldValue}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Text className="text-danger">
                    {errors.foodSubType}
                  </Form.Text>
                </div>
              </Col>
            )}
          </Row>

          <Row className="mb-3">
            <Col xl="12">
              <Form.Check
                type="switch"
                className="me-3 fs-14 fw-semibold"
                checked={formData.isDaySpecial}
                name="daySpecific"
                label={formData.isDaySpecial ? "Is Day Specific" : "Available on all days"}
                onChange={(e) => {
                  if (e.target.checked) {
                    setFormData({
                      ...formData,
                      addCatalogueOnDays: selectDaysOptions,
                      isDaySpecial: e.target.checked,
                    });
                  } else {
                    setFormData({
                      ...formData,
                      isDaySpecial: e.target.checked,
                    });
                  }
                }}
              />
            </Col>
          </Row>
          {formData.isDaySpecial && (
            <Row>
              <Col xl="6">
                <Select
                  options={selectDaysOptions}
                  isSearchable={true}
                  isMulti
                  value={formData.addCatalogueOnDays}
                  onChange={(selectedOptions) => {
                    setFormData({
                      ...formData,
                      addCatalogueOnDays: selectedOptions,
                    });
                  }}
                />
              </Col>
            </Row>
          )}
        </Offcanvas.Body>
        <div className="offcanvas-footer bg-white">
          <Button
            variant="primary"
            className="fs-14"
            type="submit"
            onClick={OnSubmit}
          >
            Update
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}

import React, { useContext, useEffect, useState } from "react";
import { Button, Offcanvas, Row, Col, Form } from "react-bootstrap";


export default function AddNewCoupon(props) {
    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-40">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">
                        Add New Coupon
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Row>
                        <Col xl={8}>
                            <div className="mb-4">
                                <Form.Label>Status</Form.Label>
                                <Form.Select name="status">
                                    <option>Choose Status</option>
                                    <option value="active">Active</option>
                                    <option value="inactive">Inactive</option>
                                </Form.Select>
                            </div>
                        </Col>

                    </Row>

                    <Row>
                        <Col xl={8}>
                            <div className="mb-4">
                                <Form.Label>Title</Form.Label>
                                <Form.Control type="text" placeholder="Enter Coupon Name" name="name" />
                            </div>
                        </Col>
                    </Row>

                    <Row className="mb-4 g-3">
                        <Col xl={6}>
                            <Form.Label>Coupen Code</Form.Label>
                            <Form.Control type="text" placeholder="Enter Coupen Code" name="CouponCode" />
                        </Col>

                        <Col xl={6}>
                            <Form.Label>Short Description</Form.Label>
                            <Form.Control type="text" placeholder="Enter Short Description" name="shortDes" />
                        </Col>
                    </Row>


                    <Row className="mb-4">
                        <Col xl={6}>
                            <Form.Label>Minimum Order Value</Form.Label>
                            <Form.Control type="text" placeholder="Enter Minimum Order Value" name="minOrder" />
                        </Col>

                        <Col xl={6}>
                            <Form.Label>Max Discount</Form.Label>
                            <Form.Control type="text" placeholder="Enter Max Discount" name="maxDis" />
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={6}>
                            <Form.Label>Discount Percentage</Form.Label>
                            <Form.Control type="text" placeholder="Enter Discount Percentage" name="minOrderPer" />
                        </Col>

                        <Col xl={6}>
                            <Form.Label>Usage Allow Per User</Form.Label>
                            <Form.Control type="text" placeholder="Enter Usage Allow Per User" name="usageAllowUser" />
                        </Col>
                    </Row>
                </Offcanvas.Body>

                <div className="offcanvas-footer justify-content-start">
                    <Button type="submit" variant="primary" className="fs-14 me-2 d-flex align-items-center">
                        <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                        <span className="align-middle">Submit</span>
                    </Button>
                </div>
            </Offcanvas>
        </React.Fragment>
    )
}
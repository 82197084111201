import { MAP_UNMAP_BRANCH_WITH_PHONENUMBER_SUCCESS, PHONENUMBER_DETAILS_WITH_BRANCH_MAPPED_SUCCESS, REGISTER_PHONENUMBER_SUCCESS, SEND_WPPHONENUMBER_VERIFICATION_OTP_SUCCESS, VERIFY_OTP_SUCCESS, WABA_BUSINESS_DETAILS_SUCCESS, WABA_PHONENUMBER_LIST_BRANCHMAP_SUCCESS } from "../actions/integrationAction";

  const initialState = {
    wabaDetails: undefined,
    wabaPhoneNumberList:undefined,
    phoneNumberDetailsWithBranchMappedList:undefined,
    sendWPPhoneNumberVerificationOtpRes:undefined,
    mapUnmapBranchWithPhonenumberSuccess:false,
    verifyOTPRes:false,
    error: null,
  };
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case WABA_BUSINESS_DETAILS_SUCCESS:
        return {
          ...state,
          wabaDetails: action.payload,
          error: null,
        };
      case WABA_PHONENUMBER_LIST_BRANCHMAP_SUCCESS:
        return {
          ...state,
          wabaPhoneNumberList: action.payload,
          error: null,
        };
      case PHONENUMBER_DETAILS_WITH_BRANCH_MAPPED_SUCCESS:
        return {
          ...state,
          phoneNumberDetailsWithBranchMappedList: action.payload,
          error: null,
        };
      case SEND_WPPHONENUMBER_VERIFICATION_OTP_SUCCESS:
        return {
          ...state,
          sendWPPhoneNumberVerificationOtpRes: action.payload,
          error: null,
        };
      case MAP_UNMAP_BRANCH_WITH_PHONENUMBER_SUCCESS:
        return {
          ...state,
          mapUnmapBranchWithPhonenumberSuccess: action.payload,
          error: null,
        };
      case REGISTER_PHONENUMBER_SUCCESS:
        return {
          ...state,
          registerPhoneNumberSuccess: action.payload,
          error: null,
        };
        case VERIFY_OTP_SUCCESS:
        return {
          ...state,
          verifyOTPRes: action.payload,
          error: null,
        };
      default:
        return state;
    }
  };
  
  export default reducer;
  